<template>
  <div class="pop-background" v-if="show" @click="popClose">
    <div class="pop-title-container">
      <img :src="`${rootUrl}/preface/preface.png`" />
    </div>
    <div
      class="pop-container"
      @click.prevent.stop="() => {}"
      v-html="content"
    ></div>
    <div class="pop-close-container" @click="popClose"></div>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    show: Boolean,
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      content: `
        <p>欢迎来到瘦吧元宇宙~</p>
        <p>瘦吧科技致力于成为大健康行业元宇宙“头号玩家”，率先利用虚拟现实技术，创新打造3D人货场、实现虚拟与现实全链路交互的元宇宙空间站，将企业品牌的发展历程、文化理念、科研实力与成果、产品服务等布景展出，让您在宇宙星河中开启健康管理征程，穿梭在虚拟与现实中感受新一代科学减脂的魅力。</p>
        <p>空间站分为四个舱，分别是：<font>核心舱、减脂舱、活动舱和会员舱</font></p>
        <p><font>核心舱</font>是品牌名片，位于空间站一楼，为初次到访的小伙伴分享瘦吧的成长印记；</p>
        <p><font>减脂舱</font>汇聚前沿减脂科学成果，让大家领略减脂辅导、低脂饮食及体脂监测的魅力；</p>
        <p><font>活动舱</font>云集各类精彩纷呈的市场活动，传递健康生活方式、践行科学营养理念；</p>
        <p><font>会员舱</font>是瘦吧星人的专属空间，大量隐藏福利等着大家解锁！</p>
        <p>朋友们，一起开启减脂之旅吧！</p>
      `,
    };
  },
  methods: {
    popClose() {
      this.$emit("close");
    },
  },
})
export default class HotspotPopContainer extends Vue {
}
</script>

<style lang="scss" scoped>
@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pop-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .pop-title-container {
    width: px2rem(204px);
    margin-bottom: px2rem(22px);
    img {
      width: 100%;
    }
  }
  .pop-container {
    z-index: 4;
    width: px2rem(628px);
    // max-height: px2rem(1403px);
    padding: px2rem(50px) px2rem(50px) px2rem(80px) px2rem(50px);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.44);
    box-shadow: 0 px2rem(8px) px2rem(40px) px2rem(1px) rgba(0, 0, 0, 0.2);
    border-radius: px2rem(30px);
    border: px2rem(2px) solid #ffffff;
    backdrop-filter: blur(10px);
    :deep(p) {
      text-align: justify;
      text-indent: 2em;
      margin: 0;
      font-size: px2rem(26px);
      line-height: px2rem(42px);
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #333333;
    }
    :deep(p font) {
      color: #00ff99;
    }
    .content-container::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  .pop-close-container {
    margin-top: px2rem(40px);
    width: px2rem(56px);
    height: px2rem(56px);
    background: url("../assets/hotspot/pop-close.png");
    background-size: 100% 100%;
  }
}
@media screen and (min-width: 600px) {
  .pop-container {
    width: px2rem(628px);
  }
}
@media screen and (max-height: 700px) {
  .pop-background {
    justify-content: flex-start;
    padding-top: px2rem(60px);
  }
}
@media screen and (min-width: 667px) and (max-width: 1240px) {
  .pop-container {
    height: 65%;
    overflow-y: auto;
  }
}
</style>
