
// 1 图 2 文 3 轮播 4 标语 5 视频 6 特定 7 下载
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import HotspotSwiper from "@/components/Common/HotspotSwiper.vue";
import HotspotDownload from "@/components/Common/HotspotDownload.vue";
import HotspotSpecial from "@/components/Common/HotspotSpecial.vue";

@Options({
  components: {
    HotspotSwiper,
    HotspotDownload,
    HotspotSpecial,
  },
  props: {
    list: Array,
    isSwiperImgNeedBorder: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["outsideIframeShow"],
  data() {
    return {
      isZsApp: false,
    };
  },
  created() {
    this.isZsApp = this.$storage.isZsApp();
  },
  methods: {
    handleVideoPlay() {
      this.$emit("audiobgstop");
    },
  },
})
export default class HotspotContentList extends Vue {}
