
import { Options, Vue } from "vue-class-component";

@Options({
  props: {},
  data() {
    return {};
  },
  methods: {
    click() {
      this.$emit("action");
    },
  },
})
export default class Back extends Vue {}
