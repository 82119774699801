
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

import HotspotLogoTips from "@/components/Common/HotspotLogoTips.vue";
import HotspotContentList from "@/components/Common/HotspotContentList.vue";

@Options({
  components: {
    HotspotLogoTips,
    HotspotContentList,
  },
  props: {
    modules: Object,
  },
  data() {
    return {};
  },
  inject: ["outsideIframeShow"],
  methods: {
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    goZsPage() {
      const _this = this;
      _this.$storage.checkAccountInfo(_this).then((info: any) => {
        if (info) {
          _this.$storage.goZsAppPage(_this, 2);
        }
      });
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
  },
})
export default class Hotspot6 extends Vue {}
