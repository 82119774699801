<template>
  <div
    :class="'cartoon-figure ' + (isMobile ? 'mobile' : '')"
    id="cartoon-figure-container"
    @click.stop.prevent="onTouchStart"
  >
    <div v-if="showTalk" class="talking-wrapper">
      <div class="talking-content" ref="talkingContent">
        <p>{{ talkContentOwn }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Options, Vue } from "vue-class-component";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { judgeMobile } from "@/assets/js/utils"

@Options({
  props: {
    show: Boolean,
    cartoonActionIndex: Number,
    showTalk: {
      type: Boolean,
      default: false
    },
    talkContent: {
      type: String,
      default: ''
    },
  },
  watch: {
    showTalk: {
      handler: function(newVal) {
        if(newVal){
          this.talkContentInit();
          this.printTalkContent();
          this.animationsPlay(Math.random() * 10 >= 5 ? 1 : 0);
        }else{
          this.talkContentRemain = '';
          this.talkContentOwn = '';
          this.animationsPlay(0);
        }
      },
      immediate: true,
      deep: true
    },
    show: {
      handler: function(newVal) {
        if(newVal){
          this.init();
        }
      },
      immediate: true,
      deep: true
    },
    cartoonActionIndex: {
      handler: function(newVal) {
        this.animationsPlay(newVal);
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      isMobile: false,
      animationsList: [],
      isInited: false,
      raycaster: {},
      mouse: {},
      camera: {},
      canvasWidth: 300,
      canvasHeight: 300,
      talkContentOwn: '',
      talkContentRemain: '',
    };
  },
  methods: {
    talkContentInit(){
      this.talkContentRemain = this.talkContent
    },
    printTalkContent(){
      if(this.talkContentRemain.length > 0){
        this.talkContentOwn = this.talkContentOwn + this.talkContentRemain.slice(0, 1);
        this.talkContentRemain = this.talkContentRemain.slice(1);
        if(this.$refs && this.$refs['talkingContent']){
          this.$refs['talkingContent'].scrollTop = 999999;
        }
        setTimeout(this.printTalkContent,220)
      }
    },
    onTouchStart(){
      // this.mouse.x = ( event.clientX / this.canvasWidth ) * 2 - 1;
      // this.mouse.y = - ( event.clientY / this.canvasHeight ) * 2 + 1;
      // this.raycaster.setFromCamera( this.mouse, this.camera );
      this.$emit('clickaction')
    },
    animationsPlay(index){
      if(!this.isInited){
        return;
      }
      this.animationsList.map(item=>{
        item.stop();
      })
      this.animationsList && this.animationsList[index] && this.animationsList[index].play();
    },
    init() {
      const that = this
      const container = document.getElementById("cartoon-figure-container");
      this.canvasWidth = 300;
      this.canvasHeight = 300;
      if(judgeMobile().mobile && window.innerWidth <= 1240){
          this.canvasWidth = 150;
          this.canvasHeight = 150;
      }
      this.camera = new THREE.PerspectiveCamera( 45, this.canvasWidth / this.canvasHeight, 1, 2000 );
      this.camera.position.set( 0, 0, 0 );
      const scene = new THREE.Scene();
      scene.add(new THREE.AmbientLight(0xffffff, 0.4)); //环境光
      const directionalLight = new THREE.DirectionalLight( 0xffffff, 1.6 );
      scene.add( directionalLight );
      this.raycaster = new THREE.Raycaster();
      this.mouse = new THREE.Vector2();
      const render = ()=>{
        renderer.render(scene, this.camera);
      }
      let mixer;
      let clock = new THREE.Clock();
      let animate = function () {
          requestAnimationFrame( animate );
          let time = clock.getDelta();
          if (mixer) {
              mixer.update(time);
          }
          controls.update();
          render();
      };

    let loader = new GLTFLoader();
    loader.load( `${process.env.BASE_URL}cartoon/03/new_anan.gltf`, function ( gltf ) {
        // console.log(gltf, 'gltf');
        mixer = new THREE.AnimationMixer( gltf.scene );
        gltf.scene.scale.x = 300;
        gltf.scene.scale.y = 300;
        gltf.scene.scale.z = 300;
        gltf.scene.position.y = -100;
        if(judgeMobile().mobile && window.innerWidth <= 1240){
          gltf.scene.scale.x = 600;
          gltf.scene.scale.y = 600;
          gltf.scene.scale.z = 600;
          gltf.scene.position.y = -200;
        }
        mixer.addEventListener( 'loop', function(  ) {
          // console.log(e, 'loop');
          // console.log(Date.parse(new Date()), 'time');
        } );
        mixer.addEventListener( 'finished', function(  ) {
          // console.log(e, 'finished');
        } );
        that.animationsList = gltf.animations.map(item=>{
          // item.duration = item.duration/10;
          let animation = mixer.clipAction(item);
          // console.log(animation, 'animation');
          animation.timeScale = 0.7;
          animation.setLoop(THREE.LoopRepeat, Infinity)
          // animation.play();
          return animation;
        })
        scene.add( gltf.scene );
        animate();
        that.isInited = true;
        setTimeout(() => {
          that.animationsList.map(item=>{
            item.stop();
          })
          that.animationsList[0].play();
        }, 1000);
      });
      const renderer = new THREE.WebGLRenderer({
        alpha: true,
      });
      renderer.setPixelRatio( window.devicePixelRatio < 1.5 ? 1.5 : window.devicePixelRatio );
      renderer.setSize( this.canvasWidth, this.canvasHeight );
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 1;
      renderer.outputEncoding = THREE.sRGBEncoding;
      container.appendChild(renderer.domElement);
      renderer.antialias = true;
      renderer.shadowMapEnabled = true; // 启用阴影选项

      var controls = new OrbitControls( this.camera, renderer.domElement );
      controls.addEventListener( 'change', render );
      if(judgeMobile().mobile && window.innerWidth <= 1240){
        this.isMobile = true;
        controls.minDistance = 800;
        controls.maxDistance = 1200;
      }else {
        this.isMobile = false;
        controls.minDistance = 400;
        controls.maxDistance = 900;
      }
      controls.enablePan = false;
      controls.enableRotate = false;
      controls.target.set( 0, 0, - 0.2 );
      controls.minPolarAngle = 1.5;
      controls.maxPolarAngle = 1.5;
      controls.update();
      const onWindowResize = ()=>{
        this.camera.aspect = this.canvasWidth / this.canvasHeight;
        this.camera.updateProjectionMatrix();
        renderer.setSize( this.canvasWidth, this.canvasHeight );
        render();
      };
      window.addEventListener( 'resize', onWindowResize );
      setTimeout(() => {
        controls.minDistance = 100;
        controls.update();
      }, 2000);
    },
  },
})
export default class CartoonFigure extends Vue {
}
</script>

<style scoped lang="scss">
.cartoon-figure {
  width: px2rem(600px);
  height: px2rem(600px);
  position: fixed;
  z-index: 11;
  left: 0;
  bottom: px2rem(250px);
}
.cartoon-figure.mobile {
  width: px2rem(300px);
  height: px2rem(300px);
}
.talking-wrapper {
  width: px2rem(318px);
  height: px2rem(179px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: px2rem(43px) px2rem(32px) px2rem(47px) px2rem(63px);
  overflow: hidden;
  background: url(#{$PublicEnv}/cartoon/cartoon_talk_bg2.png);
  background-size: 100% 100%;
  position: absolute;
  right: px2rem(-240px);
  top: px2rem(20px);
  .talking-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  p {
    font-size: px2rem(24px);
    line-height: px2rem(28px);
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
  }
  .talking-content::-webkit-scrollbar {
    width: 0 !important;
  }
}
@media screen and (min-width: 600px) {
  .talking-wrapper {
    width: px2rem(577px);
    height: px2rem(325px);
    padding: px2rem(77px) px2rem(57px) px2rem(85px) px2rem(115px);
    right: px2rem(-440px);
    top: px2rem(60px);
    p {
      font-size: px2rem(43px);
      line-height: px2rem(56px);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
@media screen and (min-width: 667px) and (max-width: 1240px) {
  .cartoon-figure {
    bottom: px2rem(150px);
  }
  .talking-wrapper {
    width: px2rem(318px);
    height: px2rem(179px);
    padding: px2rem(43px) px2rem(32px) px2rem(47px) px2rem(63px);
    right: px2rem(-250px);
    top: px2rem(20px);
    p {
      font-size: px2rem(24px);
      line-height: px2rem(28px);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
