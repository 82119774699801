
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
// import videojs from "video.js";
// import "videojs-contrib-hls";
// import "@videojs/http-streaming";
// import "video.js/dist/video-js.css";

@Options({
  props: {
    modules: Object,
  },
  created() {
    // console.log(this.modules, "进来了");
  },
  data() {
    return {
      videoId: `video_${new Date().getTime()}`,
      videoInstance: {},
    };
  },
  mounted() {
    // const _this = this;
    // setTimeout(() => {
    //   _this.videoInstance = videojs(_this.videoId, {
    //     controls: true,
    //     autoplay: true,
    //     loop: false,
    //     preload: "auto",
    //   });
    // }, 0);
  },
  methods: {
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    handleVideoPlay() {
      this.$emit("audiobgstop");
    },
  },
})
export default class Hotspot6 extends Vue {}
