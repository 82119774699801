
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";

@Options({
  components: {
    ...componentAnswerCommon,
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    doAction(i: any) {
      if (i.isReal) {
        this.$emit("action", {
          type: 1,
          url: `Address`,
          data: i,
        });
      } else {
        this.$emit("action", {
          type: 1,
          url: `CodeExchange`,
          data: i,
        });
      }
    },
    getData() {
      const _this = this;
      _this.$http
        .get("/prize-lottery/page-list", {
          per_page: 999999,
        })
        .then((res: any) => {
          if (res.code === 200) {
            res.data.data.map((i: any) => {
              _this.list.push({
                isActive: !i.is_cash,
                name: i.prize_title,
                desc: "",
                time: i.create_time,
                img: i.prize_url,
                isReal: i.is_real,
                id: i.id,
                user_name: i.user_name,
                phone: i.phone,
                region: i.region,
                address: i.address,
              });
            });
          }
        });
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    goBack() {
      this.$emit("action", {
        type: 2,
      });
    },
  },
})
export default class Hotspot6 extends Vue {}
