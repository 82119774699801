
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    text: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    subObject: {
      type: Object,
      default: null,
    },
    imgWidthType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {},
})
export default class Title extends Vue {}
