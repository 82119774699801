
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    money: {
      type: Boolean,
      default: false,
    },
    flowerRight: {
      type: Boolean,
      default: false,
    },
    circleMiddle: {
      type: Boolean,
      default: false,
    },
    moneyLong: {
      type: Boolean,
      default: false,
    },
    moneyBottom: {
      type: Boolean,
      default: false,
    },
    flowerLeft: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    circleRight: {
      type: Boolean,
      default: false,
    },
    circleRightUp: {
      type: Boolean,
      default: false,
    },
    circleTop: {
      type: Boolean,
      default: false,
    },
    cup: {
      type: Boolean,
      default: false,
    },
    flower: {
      type: Boolean,
      default: false,
    },
    spot: {
      type: Boolean,
      default: false,
    },
    starTop: {
      type: Boolean,
      default: false,
    },
    starMiddle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
})
export default class Action extends Vue {}
