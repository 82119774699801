
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {
    show: {
      handler: function (newVal) {
        if (newVal) {
          // console.log(" ");
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {},
})
export default class EnterGif extends Vue {}
