
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";
import { mobileCheck } from "@/assets/js/utils";

@Options({
  components: {
    ...componentAnswerCommon,
  },
  props: {
    modules: Object,
    data: {
      type: Object,
      default: {
        desc: "",
        img: "",
        isActive: false,
        isReal: 0,
        name: "",
        time: "",
        id: "",
      },
    },
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      name: "",
      mobile: "",
      area: "",
      address: "",
    };
  },
  methods: {
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    goBack() {
      this.$emit("action", {
        type: 2,
      });
    },
    submit() {
      const _this = this;
      if (!mobileCheck(_this.mobile)) {
        _this.$toast({
          message: "请输入正确的手机号码",
          duration: 1000,
          forbidClick: true,
        });
        return;
      }
      const requestParam = {
        id: _this.data.id,
        phone: _this.mobile,
      };
      _this.$http.post("/prize-lottery/cash", requestParam).then((res: any) => {
        if (res.code === 200) {
          _this.$toast({
            message: "兑换成功",
            duration: 1000,
            forbidClick: true,
          });
          setTimeout(() => {
            _this.goBack();
          }, 1000);
        } else {
          _this.$toast({
            message: res.msg,
            duration: 1000,
            forbidClick: true,
          });
        }
      });
    },
  },
})
export default class Hotspot6 extends Vue {}
