
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import Back from "@/components/Common/Back.vue";
import HotspotContentList from "@/components/Common/HotspotContentList.vue";

@Options({
  components: {
    Back,
    HotspotContentList,
  },
  props: {
    modules: Object,
  },
  created() {
    // console.log(this.modules, "进来了");
  },
  data() {
    return {
      popShow: false,
      popData: {},
      currentIndex: -1,
      showDetail: false,
      currentMoreIndex: -1,
      showMoreDetail: false,
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
    };
  },
  methods: {
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    showPerson(item: any) {
      // console.log(item, "item");
      if (item.intro && item.intro.length > 0) {
        this.popData = item;
        this.popShow = true;
      }
    },
    popClose() {
      this.popShow = false;
    },
    goBack() {
      this.handleAudioStop();
      if (this.showMoreDetail) {
        this.showMoreDetail = false;
        return;
      }
      this.showDetail = false;
    },
    clickMore(k: number) {
      this.currentMoreIndex = k;
      this.showMoreDetail = true;
      if (this.modules.list[this.currentIndex].detail.list[k].audio) {
        this.handleAudioPlay(
          this.modules.list[this.currentIndex].detail.list[k].audio
        );
      }
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
      if (this.modules.list[k].audio) {
        this.handleAudioPlay(this.modules.list[k].audio);
      }
    },
    handleVideoPlay() {
      this.$emit("audiobgstop");
    },
    handleAudioPlay(url: string) {
      this.$emit("audioplay", url);
    },
    handleAudioStop() {
      this.$emit("audiostop");
    },
  },
})
export default class Hotspot6 extends Vue {}
