<template>
  <PopInner
    :money="true"
    :flowerRight="true"
    :circleMiddle="true"
    :moneyBottom="true"
    :moneyLong="true"
    :light="true"
    :circleRightUp="true"
  >
    <div class="award-store-container">
      <Title
        class="title-container"
        :img="`${rootUrl}/answer/drawer_title.png`"
        :imgWidthType="3"
      />
      <div class="drawer-wrapper">
        <img class="drawer-bg" :src="`${rootUrl}/answer/drawer_cover.png`" />
        <div class="award-wrapper">
          <div
            :class="`award-item ${i.isAction ? 'is-action' : ''} ${
              k === activeIndex ? 'active' : ''
            }`"
            v-for="(i, k) in list"
            :key="k"
            @click.stop.prevent="draw(i.isAction)"
          >
            <img :src="i.img" />
            <p>{{ i.title }}</p>
          </div>
        </div>
      </div>
      <Back @action="goBack" />
    </div>
    <Poper v-if="showPopAward" @close="closePopAward" :isDrawer="true">
      <div class="pop-award-wrapper" @click.stop.prevent="() => {}">
        <span>获取{{ stopData.title }}</span>
        <img :src="stopData.img" />
        <div
          class="pop-award-action"
          @click.stop.prevent="goStore"
          v-if="stopData.is_real && stopData.show_phone"
        >
          <span>前往奖品库，兑换奖品</span>
        </div>
        <div
          class="pop-award-input"
          v-if="!stopData.is_real && !awardPhoneFinished && stopData.show_phone"
        >
          <span>手机号</span>
          <input v-model="awardPhone" placeholder="填写有效手机号兑换奖品" />
        </div>
        <div
          class="pop-award-action"
          @click.stop.prevent="getAward"
          v-if="!stopData.is_real && !awardPhoneFinished && stopData.show_phone"
        >
          <span>马上领奖品</span>
        </div>
        <div v-if="awardPhoneFinished" class="pop-award-tips">
          <span>奖品将在14日内兑换，敬请留意~</span>
        </div>
        <div
          class="pop-award-action"
          @click.stop.prevent="closePopAward"
          v-if="!stopData.show_phone"
        >
          <span>确定</span>
        </div>
      </div>
    </Poper>
  </PopInner>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";
import { mobileCheck } from "@/assets/js/utils"
const drawNext = {
  0: 1,
  1: 2,
  2: 5,
  5: 8,
  8: 7,
  7: 6,
  6: 3,
  3: 0,
};
@Options({
  components: {
    ...componentAnswerCommon,
  },
  props: {
    modules: Object,
    data: Object,
  },
  data() {
    const rootUrl = process.env.VUE_APP_DEPLOY_URL;
    const timeCountInit = 200;
    return {
      rootUrl: rootUrl,
      activeIndex: 0,
      timeCountMin: 50,
      timeCountMax: 400,
      isTimeCountTurn: false,
      timeCountStep: 25,
      timeCountInit: timeCountInit,
      timeCount: timeCountInit,
      stopIndex: -1,
      stopId: -1,
      stopData: {
        title: ``,
        img: ``,
      },
      list: [],
      awardPhone: '',
      awardPhoneFinished: false,
      showPopAward: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    goStore(){
      this.$emit("action", {
        type: 1,
        url: `AwardStore`,
      });
    },
    closePopAward() {
      this.showPopAward = false;
    },
    checkList(list){
      const _this = this
      list.map((item, index) => {
        if(_this.list.length === 4){
          _this.list.push(
          {
            isAction: true,
            img: `${_this.rootUrl}/answer/drawer_action_bg.png`,
            title: ``,
          });
        }
        if (index < 8 && _this.list.length < 9) {
          _this.list.push({
            id: item.id,
            img: item.url,
            title: item.title,
          });
        }
      });
      if(_this.list.length < 9){
        _this.checkList(list);
      }
    },
    getData() {
      const _this = this;
      _this.$http.get("/prize/list").then((res) => {
        if (res.code === 200) {
          _this.checkList(res.data.prize_list);
        }
      });
    },
    resetTimeCount(){
      this.isTimeCountTurn = false;
      // this.stopIndex = -1;
      this.timeCount = this.timeCountInit;
    },
    begin(){
      this.activeIndex = drawNext[this.activeIndex];
      if(this.activeIndex == this.stopIndex && this.isTimeCountTurn && this.timeCount >= this.timeCountMax){
        this.showPopAward = true;
        this.resetTimeCount();
        return;
      }
      setTimeout(() => {
        if(!this.isTimeCountTurn){
          if(this.timeCount > this.timeCountMin){
            this.timeCount -= this.timeCountStep
          }
          this.begin()
        }else{
          if(this.timeCount < this.timeCountMax){
            this.timeCount += this.timeCountStep
          }
          this.begin()
        }
      }, this.timeCount);
    },
    getAward(){
      const _this = this
      if(_this.stopData.is_real){
        return;
      }
      if(!mobileCheck(_this.awardPhone)){
        _this.$toast({
          message: "请输入正确的手机号码",
          duration: 1000,
          forbidClick: true,
        });
        return;
      }
      const requestParam = {
        id: _this.stopData.detail_id,
        phone: _this.awardPhone,
      };
      _this.$http.post("/prize-lottery/cash", requestParam).then((res) => {
        if (res.code === 200) {
          _this.$toast({
            message: "兑换成功",
            duration: 1000,
            forbidClick: true,
          });
          _this.awardPhoneFinished = true;
        } else {
          _this.$toast({
            message: res.msg,
            duration: 1000,
            forbidClick: true,
          });
        }
      });
    },
    draw(doAction){
      const _this = this;
      if(doAction){
        let requestParam = {};
        if(_this.data && _this.data.id){
          requestParam = {
            detail_id: _this.data.id
          }
        }
        _this.$http.post("/prize-lottery/lottery", requestParam).then((res) => {
          if (res.code === 200) {
            _this.stopId = res.data.id;
            for(let i = 0; i<_this.list.length; i++){
              if(_this.list[i].id && _this.list[i].id == _this.stopId){
                _this.stopIndex = i;
                break;
              }
            }
            _this.stopData = {
              title: res.data.title,
              img: res.data.url,
              detail_id: res.data.lottery_detail_id,
              is_real: res.data.is_real,
              show_phone: res.data.show_phone
            }
            this.begin();
            setTimeout(() => {
              this.isTimeCountTurn = true;
            }, 5000);
          }else{
            _this.$toast({
              message: res.msg,
              duration: 1000,
              forbidClick: true,
            });
          }
        });
      }
    },
    click(k) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    goBack() {
      this.$emit("action", {
        type: 2,
      });
    },
  },
})
export default class Hotspot6 extends Vue {}
</script>

<style scoped lang="scss">
.award-store-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  padding-bottom: px2rem(32px);
  .title-container {
    padding-top: px2rem(91px);
  }
  .drawer-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 px2rem(20px);
    margin-top: px2rem(144px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img.drawer-bg {
      width: px2rem(680px);
    }
    .award-wrapper {
      position: absolute;
      top: px2rem(109px);
      left: px2rem(78px);
      right: px2rem(78px);
      bottom: px2rem(300px);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .award-item {
        width: px2rem(150px);
        height: px2rem(150px);
        background: #ffffff;
        box-shadow: 0px px2rem(16px) 0 0 rgba(107, 205, 147, 0.8);
        border-radius: px2rem(10px);
        margin-bottom: px2rem(34px);
        margin-right: px2rem(24px);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;
        box-sizing: border-box;
        overflow: hidden;
        img {
          height: px2rem(74px);
        }
        p {
          margin-top: px2rem(19px);
          font-size: px2rem(24px);
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #31d896;
        }
      }
      .award-item:nth-child(3n) {
        margin-right: 0;
      }
      .award-item:nth-child(7),
      .award-item:nth-child(8),
      .award-item:nth-child(9) {
        margin-bottom: 0;
      }
      .award-item.is-action {
        img {
          width: px2rem(154px);
          height: px2rem(154px);
        }
        p {
          margin: 0;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          font-size: px2rem(42px);
          line-height: px2rem(46px);
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          width: px2rem(88px);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .award-item.active {
        background: #defaef;
        border: px2rem(2px) solid #00ce7c;
      }
    }
  }
}
.award-store-container::-webkit-scrollbar {
  width: 0 !important;
}
.pop-award-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-top: px2rem(242px);
    font-size: px2rem(36px);
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #814228;
    text-align: center;
  }
  img {
    margin-top: px2rem(35px);
    height: px2rem(272px);
  }
  .pop-award-input {
    margin-top: px2rem(50px);
    width: px2rem(500px);
    height: px2rem(88px);
    background: #ffffff;
    border-radius: px2rem(10px);
    border: px2rem(1px) solid #cccccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 px2rem(30px);
    span {
      flex-shrink: 0;
      font-size: px2rem(29px);
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin: 0;
      margin-right: px2rem(10px);
    }
    input {
      flex: 1;
      font-size: px2rem(29px);
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      border: 0;
    }
  }
  .pop-award-action {
    margin-top: px2rem(50px);
    width: px2rem(500px);
    height: px2rem(88px);
    background: linear-gradient(180deg, #f5703f 0%, #ffab41 100%);
    box-shadow: 0 px2rem(10px) px2rem(30px) px2rem(1px) rgba(246, 115, 64, 0.25);
    border-radius: px2rem(44px);
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: px2rem(32px);
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      margin: 0;
    }
  }
  .pop-award-tips {
    width: 100%;
    margin-top: px2rem(50px);
    text-align: center;
    span {
      font-size: px2rem(26px);
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
