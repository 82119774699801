
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  inject: ["outsideIframeShow"],
  methods: {
    goUrl() {
      if (this.data.info.url) {
        // this.outsideIframeShow(this.data.info.url);
        window.open(this.data.info.url);
      }
      // window.open("http://shidai.sahhealthgroup.com/api/goodsList");
    },
  },
})
export default class HotspotSpecial extends Vue {}
