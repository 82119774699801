
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswer from "@/assets/js/componentAnswer";
const componentDefaultTemplate = [
  "AwardStore",
  "Answer",
  "AnswerRecord",
  "DrawIndex",
];
@Options({
  components: {
    ...componentAnswer,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      template: "normal",
      templatePrev: "normal",
      answerComponent: "Index",
      answerComponentPrev: "Index",
      data: {},
      dataPrev: {},
      needCup: false,
    };
  },
  methods: {
    getComponentTemplate(component: string) {
      return componentDefaultTemplate.includes(component) ? "" : "normal";
    },
    doAction(action: any) {
      if (action) {
        if (action.type === 1) {
          this.answerComponentPrev = this.answerComponent;
          this.templatePrev = this.getComponentTemplate(this.answerComponent);
          this.answerComponent = action.url;
          this.template = this.getComponentTemplate(this.answerComponent);
          // console.log(action, "action");
          if (action.data) {
            this.data = action.data;
          }
        } else if (action.type === 2) {
          if (this.answerComponentPrev == this.answerComponent) {
            this.answerComponentPrev = `Index`;
          }
          this.answerComponent = this.answerComponentPrev;
          this.data = this.dataPrev;
          this.template = this.getComponentTemplate(this.answerComponent);
        }
      }
    },
    popClose() {
      this.$emit("close");
    },
  },
})
export default class AnswerPopContainer extends Vue {
  msg!: string;
}
