
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    showBuy: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    actionText: {
      type: String,
      default: "",
    },
    actionStyle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    click() {
      this.$emit("action");
    },
    buy() {
      this.$emit("buy");
    },
    create() {
      this.$emit("create");
    },
  },
})
export default class Back extends Vue {}
