
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    text: {
      type: String,
      default: "",
    },
    style: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    click() {
      this.$emit("action");
    },
  },
})
export default class Action extends Vue {}
