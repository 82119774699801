
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";

@Options({
  components: {
    ...componentAnswerCommon,
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
    };
  },
  methods: {
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    goBack() {
      this.$emit("action", {
        type: 2,
      });
    },
  },
})
export default class Hotspot6 extends Vue {}
