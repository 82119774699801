
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

import HotspotContentList from "@/components/Common/HotspotContentList.vue";

@Options({
  components: {
    HotspotContentList,
  },
  props: {
    modules: Object,
  },
  data() {
    return {};
  },
  methods: {
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
  },
})
export default class Hotspot6 extends Vue {}
