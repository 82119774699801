
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

import HotspotContentList from "@/components/Common/HotspotContentList.vue";
import Back from "@/components/Common/Back.vue";
import vueDanmaku from "vue3-danmaku";

@Options({
  components: {
    HotspotContentList,
    Back,
    vueDanmaku,
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      popShow: false,
      showDetail: false,
      myMessage: "",
      danmus: [],
      list: [],
    };
  },
  mounted() {
    this.messageInit();
    this.myMessageInit();
  },
  methods: {
    myMessageInit() {
      const _this = this;
      _this.$http.get("/feedback/my-list").then((res: any) => {
        if (res.code === 200) {
          _this.list = [...res.data];
        }
      });
    },
    messageInit() {
      const _this = this;
      _this.$http.get("/feedback/list").then((res: any) => {
        if (res.code === 200) {
          // console.log(res, "res");
          res.data.map((item: any) => {
            _this.danmus.push({
              name: item.account.account_name,
              text: item.content,
              avatar: item.account.avatar,
            });
          });
          // console.log(_this.danmus, "_this.danmus");
          _this.$nextTick(() => {
            const danmaku = _this.$refs.danmaku;
            danmaku.reset();
            danmaku.play();
          }, 0);
        }
      });
    },
    messageAdd() {
      const _this = this;
      _this.$storage.checkAccountInfo(_this).then((info: any) => {
        if (info) {
          _this.$http
            .post("/feedback/create", {
              content: _this.myMessage,
            })
            .then((res: any) => {
              if (res.code === 200) {
                _this.$toast({
                  message: "发送成功",
                  duration: 1000,
                  forbidClick: true,
                });
                _this.myMessage = "";
                _this.popClose();
                _this.messageInit();
              } else {
                _this.$toast({
                  message: res.msg,
                  duration: 1000,
                  forbidClick: true,
                });
              }
            });
        }
      });
    },
    popClose() {
      this.popShow = false;
    },
    showPop() {
      this.myMessage = "";
      this.popShow = true;
    },
    click() {
      const _this = this;
      _this.$storage.checkAccountInfo(_this).then((info: any) => {
        if (info) {
          _this.showDetail = true;
          _this.$emit("bgchange");
        }
      });
    },
    goBack() {
      this.showDetail = false;
      this.$emit("bginit");
    },
  },
})
export default class Hotspot6 extends Vue {}
