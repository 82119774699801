
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

import Back from "@/components/Common/Back.vue";
import HotspotSwiper from "@/components/Common/HotspotSwiper.vue";

@Options({
  components: {
    HotspotSwiper,
    Back,
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      showDetail: false,
      popShow: false,
      currentDate: "",
      currentItem: {},
      masks: {
        title: "YYYY年M月",
        weekdays: "W",
      },
      attributes: [],
    };
  },
  mounted() {
    this.currentDate = this.getCurrentDate();
    this.activityInit();
  },
  methods: {
    goBack() {
      this.showDetail = false;
    },
    goActivity(item: any) {
      this.currentItem = item;
      this.showDetail = true;
    },
    calendarChange() {
      const _this = this;
      this.$nextTick(() => {
        const date = _this.$refs.calendar.firstPage.title;
        const year = date.substr(0, 4);
        let month = date.substr(5).replace("月", "");
        if (parseInt(month) < 10) {
          month = "0" + month;
        }
        _this.currentDate = year + "-" + month;
        _this.activityInit();
      });
    },
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      let clock = year + "-";
      if (month < 10) clock += "0";
      clock += month;
      return clock;
    },
    activityInit() {
      const _this = this;
      const requestParam = {
        activity_month: this.currentDate,
      };
      _this.$http.get("/activity/list", requestParam).then((res: any) => {
        // console.log(res, "res");
        if (res.code === 200) {
          // console.log(res, "res");
          this.attributes = [];
          res.data.map((item: any) => {
            const mainImgNew = item.main_img.map((i: string) => {
              return { img: i };
            });
            item.main_img = mainImgNew;
            this.attributes.push({
              customData: {
                title: item.title,
                data: item,
              },
              dates: new Date(item.start_time),
            });
          });
        }
      });
    },
    popClose() {
      this.popShow = false;
    },
    showPop() {
      this.myMessage = "";
      this.popShow = true;
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
  },
})
export default class Hotspot6 extends Vue {}
