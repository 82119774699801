
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import { Swiper, SwiperSlide, useSwiper } from "swiper/vue/swiper-vue";
import { Autoplay, Navigation } from "swiper";
import "swiper/swiper.min.css";

@Options({
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    list: Array,
    isImgNeedBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      swiperModules: [Navigation, Autoplay],
      swiper: useSwiper(),
      currentTime: new Date().getTime(),
    };
  },
  methods: {
    handleVideoPlay() {
      this.$emit("audiobgstop");
    },
    onSwiper(swiper: any) {
      swiper.slideNext();
    },
    // onSlideChange() {
    //   // console.log("slide change");
    // },
  },
})
export default class HotspotSwiper extends Vue {}
