
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";

@Options({
  components: {
    ...componentAnswerCommon,
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    doAction(i: any) {
      if (i.isActive) {
        this.$emit("action", {
          type: 1,
          url: `DrawIndex`,
          data: i,
        });
      }
    },
    getData() {
      const _this = this;
      _this.$http.get("/question/answer_detail").then((res: any) => {
        if (res.code === 200) {
          // console.log(res, "res");
          _this.list = res.data.map((item: any) => {
            let rankName = "倔强青铜";
            if (
              item.answerSetTitle &&
              item.answerSetTitle != null &&
              item.answerSetTitle.title
            ) {
              rankName = item.answerSetTitle.title;
            }
            return {
              rankName: rankName,
              rankIndex: _this.getRankIndexByName(rankName),
              correct: item.answer_right,
              rank: "1",
              time: item.create_time,
              isActive: item.is_lottery,
              id: item.id,
            };
          });
        }
      });
    },
    getRankIndexByName(rankName: string) {
      return rankName == "至尊星耀"
        ? "0"
        : rankName == "最强王者"
        ? "1"
        : rankName == "永恒钻石"
        ? "2"
        : rankName == "尊贵铂金"
        ? "3"
        : rankName == "荣耀黄金"
        ? "4"
        : rankName == "倔强青铜"
        ? "5"
        : "5";
    },
    getRankName(rankIndex: string) {
      return rankIndex == "0"
        ? "至尊星耀"
        : rankIndex == "1"
        ? "最强王者"
        : rankIndex == "2"
        ? "永恒钻石"
        : rankIndex == "3"
        ? "尊贵铂金"
        : rankIndex == "4"
        ? "荣耀黄金"
        : rankIndex == "5"
        ? "倔强青铜"
        : "";
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    goBack() {
      this.$emit("action", {
        type: 2,
      });
    },
  },
})
export default class Hotspot6 extends Vue {}
