
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
const defaultList = [
  {
    type: -1,
    img: require(`@/assets/nav/bg_music_play.png`),
    imgClose: require(`@/assets/nav/bg_music_stop.png`),
  },
  {
    type: 0,
    img: require(`@/assets/nav/kjz@2x.png`),
    text: `简介`,
  },
  {
    type: 2,
    img: require(`@/assets/nav/sc@2x.png`),
    text: `瘦吧商城`,
  },
  {
    type: 3,
    img: require(`@/assets/nav/wd@2x.png`),
    text: `每日答题`,
  },
  {
    type: 4,
    img: require(`@/assets/nav/map.png`),
    text: `瘦吧地图`,
  },
  {
    type: 5,
    img: require(`@/assets/nav/kff@2x.png`),
    text: `客服与帮助`,
  },
  {
    type: 6,
    img: require(`@/assets/nav/activity.png`),
    text: `最新活动`,
  },
];
const shareList = [
  {
    type: 7,
    img: require(`@/assets/nav/share.png`),
    text: `一键分享`,
  },
];
const list = [...defaultList, ...shareList];
@Options({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    musicOn: Boolean,
  },
  data() {
    return {
      defaultList: defaultList,
      list: list,
      isApp: false,
      isWechat: false,
    };
  },
  watch: {
    show: {
      handler: function (newVal) {
        if (newVal) {
          this.isApp = this.$storage.isZsApp();
          if (this.$storage.judgeMobile().wechat) {
            this.list = this.defaultList;
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    click(item: any) {
      this.$emit("action", item);
    },
  },
})
export default class RightNav extends Vue {}
