
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    popClose() {
      this.$emit("popclose");
    },
  },
})
export default class NotFinishedContainer extends Vue {}
