
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

import Back from "@/components/Common/Back.vue";

@Options({
  components: {
    Back,
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      currentIndex: 0,
      showDetail: false,
    };
  },
  inject: ["outsideIframeShow"],
  methods: {
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    buyAction() {
      const _this = this;
      if (_this.modules.list[_this.currentIndex].title === "智能体脂秤") {
        _this.$storage.checkAccountInfo(_this).then((info: any) => {
          if (info) {
            _this.$storage.goZsAppPage(_this, 2);
          }
        });
      } else if (
        _this.modules.list[_this.currentIndex].title === "智能运动跳绳"
      ) {
        _this.$storage.checkAccountInfo(_this).then((info: any) => {
          if (info) {
            _this.$storage.goZsAppPage(_this, 3);
          }
        });
      } else if (
        _this.modules.list[_this.currentIndex].title === "瘦吧体脂秤" ||
        _this.modules.list[_this.currentIndex].title === "智能蓝牙体脂秤"
      ) {
        _this.$storage.checkAccountInfo(_this).then((info: any) => {
          if (info) {
            _this.$storage.goZsAppPage(_this, 12);
          }
        });
      }
    },
    goBack() {
      this.showDetail = false;
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
  },
})
export default class Hotspot6 extends Vue {}
