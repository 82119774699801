
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import Back from "@/components/Common/Back.vue";
import HotspotLogoTips from "@/components/Common/HotspotLogoTips.vue";
import HotspotSwiper from "@/components/Common/HotspotSwiper.vue";
import HotspotContentList from "@/components/Common/HotspotContentList.vue";

@Options({
  components: {
    Back,
    HotspotLogoTips,
    HotspotSwiper,
    HotspotContentList,
  },
  props: {
    modules: Object,
  },
  created() {
    // console.log(this.modules, "进来了");
  },
  data() {
    return {
      currentIndex: 0,
      showDetail: false,
    };
  },
  methods: {
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    goBack() {
      this.showDetail = false;
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
  },
})
export default class Hotspot6 extends Vue {}
