
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";

@Options({
  components: {
    ...componentAnswerCommon,
  },
  props: {
    modules: Object,
    data: Object,
  },
  data() {
    const rootUrl = process.env.VUE_APP_DEPLOY_URL;
    return {
      rootUrl: rootUrl,
      list: [],
      awardCount: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const _this = this;
      _this.$http.get("/prize/list").then((res: any) => {
        if (res.code === 200) {
          res.data.prize_list.map((item: any, index: number) => {
            if (index <= 5) {
              _this.list.push({
                img: item.url,
                title: item.title,
              });
            }
          });
          _this.awardCount = res.data.lottery_num;
        }
      });
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    goStore() {
      this.$emit("action", {
        type: 1,
        url: "AwardStore",
      });
    },
    goDraw() {
      const _this = this;
      _this.$http.get("/prize/get-active-switch").then((res: any) => {
        if (res.code === 200) {
          if (res.data.status) {
            this.$emit("action", {
              type: 1,
              url: "Drawer",
              data: this.data,
            });
          } else {
            this.$toast({
              message: "奖品更新中，请稍候回来进行抽奖",
              duration: 1500,
            });
          }
        }
      });
    },
    goBack() {
      this.$emit("action", {
        type: 1,
        url: `Index`,
      });
    },
  },
})
export default class Hotspot6 extends Vue {}
