
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";

@Options({
  components: {
    ...componentAnswerCommon,
  },
  props: {
    modules: Object,
  },
  data() {
    const timeCountInit = 20;
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      subOject: {
        subTitle: "INTERESTING  Q & A",
        desc: "瘦吧 新一代科学减脂APP",
      },
      questionData: {
        id: ``,
        title: ``,
        answer: [],
        answerRight: `0`,
      },
      isAnswering: false,
      isAnswerCorrect: false,
      isActionAlready: false,
      nextAnswerRight: "0",
      answerRightKey: ``,
      answerRightDesc: ``,
      showPopAward: false,
      showPopLogout: false,
      timeCountInit: timeCountInit,
      timeCount: timeCountInit,
      timeCountStop: false,
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    getAnswerRightRemain() {
      if (this.questionData.answerRight) {
        return 5 - parseInt(this.questionData.answerRight);
      }
      return 5;
    },
  },
  methods: {
    timeCountReset() {
      this.timeCount = this.timeCountInit;
      this.timeCountStop = false;
    },
    timeCountBegin() {
      if (!this.timeCountStop) {
        if (this.timeCount > 0) {
          setTimeout(() => {
            this.timeCount = this.timeCount - 1;
            this.timeCountBegin();
          }, 1000);
        } else {
          this.logout();
        }
      }
    },
    popLogout() {
      const _this = this;
      if (parseInt(_this.questionData.answerRight) >= 5) {
        _this.answerOver().then((res: any) => {
          if (res.code == 200) {
            _this.$emit("action", {
              type: 1,
              url: `AnswerOver`,
              data: {
                isCorrect: true,
                score: res.data.grade,
                rankName: res.data.answer_set_title,
                answerRight: res.data.answer_right,
              },
            });
          }
        });
      } else {
        _this.showPopLogout = true;
      }
    },
    closePopLogout() {
      this.showPopLogout = false;
    },
    closePopAward() {
      this.showPopAward = false;
    },
    resetAnswer() {
      const _this = this;
      _this.answerRightKey = "";
      _this.answerRightDesc = "";
      _this.isActionAlready = false;
      _this.isAnswerCorrect = false;
      _this.questionData.id = ``;
      _this.questionData.title = ``;
      _this.questionData.answer = ``;
    },
    answerOver() {
      const _this = this;
      return _this.$http.get("/question/answer");
    },
    goDraw() {
      const _this = this;
      _this.answerOver().then((res: any) => {
        if (res.code == 200) {
          _this.$emit("action", {
            type: 1,
            url: `DrawIndex`,
          });
        }
      });
    },
    goOnAnswer() {
      if (this.isAnswerCorrect) {
        this.showPopAward = false;
        this.resetAnswer();
        this.getData();
      } else {
        // this.answerOver();
        // this.$emit("action", {
        //   type: 1,
        //   url: `AnswerOver`,
        //   data: {
        //     isCorrect: false,
        //   },
        // });
        this.logout();
      }
    },
    doAnswer(item: any, index: number) {
      const _this = this;
      if (_this.isAnswering) {
        return;
      }
      _this.isAnswering = true;
      _this.timeCountStop = true;
      const requestParam = {
        question_id: _this.questionData.id,
        answer_key: item.key,
      };
      _this.$http
        .post("/question/check_answer", requestParam)
        .then((res: any) => {
          _this.isAnswering = false;
          if (res.code === 200) {
            // console.log(res, "res");
            if (res.data.is_right) {
              _this.questionData.answer[index].is_right = true;
              _this.isAnswerCorrect = true;
            } else {
              _this.isAnswerCorrect = false;
              if (index != -1) {
                _this.questionData.answer[index].is_false = true;
              }
              _this.questionData.answer.map((i: any, k: number) => {
                if (i.key == res.data.answer_key) {
                  _this.questionData.answer[k].is_right = true;
                }
              });
            }
            _this.questionData.answerRight = res.data.answer_right;
            _this.answerRightKey = res.data.answer_key;
            _this.answerRightDesc = res.data.desc;
            _this.isActionAlready = true;
            _this.showPopAward = res.data.get_lottery;
          }
        });
    },
    getData() {
      const _this = this;
      _this.$http.get("/question/list").then((res: any) => {
        if (res.code === 200) {
          // console.log(res, "res");
          if (res.data && res.data != null && res.data.id) {
            _this.questionData.id = res.data.id;
            _this.questionData.title = res.data.title;
            _this.questionData.answer = res.data.answer;
            _this.questionData.answerRight = res.data.answer_right;
            _this.nextAnswerRight = res.data.next_answer_right;
            _this.timeCountReset();
            _this.timeCountBegin();
          } else {
            _this.logout();
          }
        }
      });
    },
    logout() {
      const _this = this;
      _this.answerOver().then((res: any) => {
        if (res.code == 200) {
          if (parseInt(_this.questionData.answerRight) >= 5) {
            _this.$emit("action", {
              type: 1,
              url: `AnswerOver`,
              data: {
                isCorrect: true,
                score: res.data.grade,
                rankName: res.data.answer_set_title,
                answerRight: res.data.answer_right,
                img: res.data.img,
                share_title: res.data.share_title,
              },
            });
          } else {
            _this.$emit("action", {
              type: 1,
              url: `AnswerOver`,
              data: {
                isCorrect: false,
                score: res.data.grade,
                rankName: res.data.answer_set_title,
                answerRight: res.data.answer_right,
                img: res.data.img,
                share_title: res.data.share_title,
              },
            });
          }
        }
      });
    },
    goIndex() {
      this.$emit("action", {
        type: 1,
        url: `Index`,
      });
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    goBack() {
      this.$emit("action", {
        type: 2,
      });
    },
  },
})
export default class Hotspot6 extends Vue {}
