
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";

@Options({
  components: {
    ...componentAnswerCommon,
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      rank: 0,
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const _this = this;
      _this.$http.get("/question/answer_top").then((res: any) => {
        if (res.code === 200) {
          // console.log(res, "res");
          _this.rank = res.data.self_top;
          _this.list = res.data.list;
        }
      });
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    goBack() {
      this.$emit("action", {
        type: 2,
      });
    },
  },
})
export default class Hotspot6 extends Vue {}
