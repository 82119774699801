
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    url: String,
    show: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.$storage.isZsApp()) {
      this.isPhone = true;
    }
  },
  data() {
    return {
      isCover: true,
      isPhone: false,
    };
  },
  methods: {
    iframeLoad(e: any) {
      // console.log(e, "e");
      if (e) {
        // console.log(e, "e");
        // this.isCover = false;
      }
    },
  },
})
export default class BackgroundIframe extends Vue {
  msg!: string;
}
