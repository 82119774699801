
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    modules: Object,
  },
  data() {
    return {
      currentNav: 0,
    };
  },
  methods: {
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    click(k: number) {
      this.currentNav = k;
    },
  },
})
export default class Hotspot6 extends Vue {}
