<template>
  <div class="hotspot-module-container" v-if="modules">
    <div class="cover-wrapper">
      <div class="top-wrapper">
        <div class="item-wrapper">
          <img :src="img" />
          <div class="content-title">
            <span>结语</span>
          </div>
          <div class="content-wrapper">
            <p>{{ content }}</p>
          </div>
          <div class="bottom-wrapper">
            <div class="bottom-left">
              <p v-for="(i, k) in modules.top.intro" :key="`bottom_left_${k}`">
                {{ i }}
              </p>
            </div>
            <div class="bottom-right">
              <vue-qrcode
                :value="qrcodeValue"
                tag="img"
                :options="qrcodeOptions"
              ></vue-qrcode>
            </div>
          </div>
        </div>
        <div class="button-wrapper" @click.stop.prevent="showPlaybill">
          <img :src="`${rootUrl}/hotspot/final/34/button_0.png`" />
        </div>
      </div>
    </div>
    <van-popup
      :style="{
        position: 'fixed',
        overflow: 'hidden',
        background: 'transparent',
      }"
      v-model:show="isPlaybillShow"
      @closed="closePlaybill"
      teleport="#app"
    >
      <div
        class="playbill-wrapper"
        ref="playbill"
        @click="createPlaybill"
        v-if="!isPlaybillAlready && isPlaybillShow"
      >
        <div class="playbill-container">
          <div class="playbill-title">
            <span>瘦吧元宇宙</span>
            <img :src="`${rootUrl}/hotspot/final/34/share_texture.png`" />
          </div>
          <div class="playbill-image">
            <img :src="imgBase64" />
          </div>
          <div class="playbill-content">
            <div class="content-left">
              <img :src="avatarBase64" />
              <div class="content-left-right">
                <span>{{ name }}</span>
                <span>{{ time }}</span>
              </div>
            </div>
            <div class="content-right">
              <span
                >第<font>{{ top }}</font
                >名登陆者</span
              >
            </div>
          </div>
          <div class="playbill-bottom">
            <div class="bottom-left">
              <p v-for="(i, k) in modules.top.intro" :key="`bottom_left_${k}`">
                {{ i }}
              </p>
            </div>
            <div class="bottom-right">
              <vue-qrcode
                :value="qrcodeValue"
                tag="img"
                :options="qrcodeOptions"
              ></vue-qrcode>
            </div>
          </div>
        </div>
      </div>
      <div
        class="playbill-final"
        v-if="isPlaybillAlready && isPlaybillShow"
        @click="createPlaybill"
      >
        <img :src="playbillBase64" />
      </div>
      <div
        class="image-save-wrapper-bg"
        v-if="isPlaybillAlready && isPlaybillShow"
      >
        <div class="image-save-wrapper" @click="createPlaybill">
          <span>点击保存图片</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

import HotspotContentList from "@/components/Common/HotspotContentList.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import html2canvas from 'html2canvas'

@Options({
  components: {
    HotspotContentList,
    VueQrcode,
    html2canvas
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      img: "",
      imgBase64: "",
      avatar: "",
      avatarBase64: "",
      content: "",
      qrcodeValue: "",
      qrcodeOptions: {
        errorCorrectionLevel: "Q",
      },
      isPlaybillShow: false,
      playbillBase64: '',
      isPlaybillAlready: false,
      isGetPlaybillImageFinished: false,
      loadingToast: {},
      top: 99999,
      name: '',
      time: this.getCurrentDate(),
    };
  },
  inject: ["outsideIframeShow"],
  created() {
    Promise.all([this.getImg(), this.getContent(), this.getQrcodeValue()]);
    this.qrcodeValue = this.$storage.ZS_DOWNLOAD_URL;
  },
  mounted() {
    // setTimeout(() => {
    //   this.qrcodeValue = "https://www.baidu.com/";
    // }, 1500);
  },
  methods: {
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDay();
      let clock = year + "-";
      if (month < 10) clock += "0";
      clock += month;
      clock += "-";
      if (day < 10) clock += "0";
      clock += day;
      return clock;
    },
    imgInit(res) {
      if (res.code === 200 && res.data) {
        this.img = res.data.img_url;
      }
    },
    contentInit(res) {
      if (res.code === 200 && res.data) {
        this.content = res.data.content;
        this.top = res.data.account?.top ? res.data.account?.top : this.top;
        this.name = res.data.account?.account_name ? res.data.account?.account_name : ''
        this.avatar = res.data.account?.avatar ? res.data.account?.avatar : ''
      }
    },
    getImg() {
      return this.$http
        .get("/share-img/info")
        .then((res) => this.imgInit(res));
    },
    getContent() {
      return this.$http
        .get("/share-slogan/info")
        .then((res) => this.contentInit(res));
    },
    getQrcodeValue() {
      return this.$http
        .post("/share-img/share", {
          method: 101,
          share_channel: 101,
          share_url: process.env.SHARE_URL
        })
        .then((res) => this.qrcodeValueInit(res));
    },
    qrcodeValueInit(res){
      if(res.code === 200){
        // console.log(res, 'res')
        this.qrcodeValue = res.data.shareRegisterUrl
      }
    },
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    click(k) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    generatePlaybill(){
      const _this = this
      // 生成头像/图片base64解决跨域问题
      let imgPromise = Promise.resolve(''), avatarPromise = Promise.resolve('');
      const img = this.img.indexOf('?')>-1?this.img.split('?')[0]:this.img
      const avatar = this.avatar.indexOf('?')>-1?this.avatar.split('?')[0]:this.avatar
      if(this.img){
        imgPromise = this.$storage.getUrlBase64(img)
      }
      if(this.avatar){
        avatarPromise = this.$storage.getUrlBase64(avatar)
      }
      Promise.all([imgPromise, avatarPromise]).then((res)=>{
        if(res[0]){
          _this.imgBase64 = res[0]
        }
        if(res[1]){
          _this.avatarBase64 = res[1]
        }
        _this.isPlaybillShow = true;
        _this.$nextTick(()=>{
          html2canvas(_this.$refs.playbill, {
            backgroundColor: null,
            useCORS: true,
            // allowTaint: true,
          }).then((canvas) => {
            let url = canvas.toDataURL('image/png');
            _this.playbillBase64 = url;
            _this.isPlaybillAlready = true;
          })
        })
      })
    },
    showPlaybill(){
      const _this = this
      _this.$storage.checkAccountInfo(_this).then((info) => {
        if (info) {
          _this.generatePlaybill()
        }
      });
    },
    closePlaybill(){
      this.isPlaybillShow = false;
      this.isPlaybillAlready = false;
    },
    getUrlBase64(url) {
      return this.$storage.getUrlBase64(url)
    },
    createPlaybill(){
      const _this = this
      const timedate = Date.parse(new Date());
      const fileName = `分享海报-${timedate}`
      const form = new FormData();
      form.append("file", _this.$storage.base64ToFile(_this.playbillBase64, fileName) );
      _this.$http.uploads("/common/upload", form).then((res)=>{
        if(res.data.code === 200 && res.data.data.url){
          _this.$storage.zsImageShare(fileName, _this.playbillBase64, res.data.data.url, _this.qrcodeValue)
        }
      })
    },
  },
})
export default class Hotspot6 extends Vue {}
</script>

<style scoped lang="scss">
.hotspot-module-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  .cover-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: px2rem(40px);
    overflow-y: auto;
    position: relative;
  }
  .top-wrapper {
    width: 100%;
    box-sizing: border-box;
    margin-top: px2rem(30px);
    .item-wrapper {
      margin: 0 px2rem(45px);
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0 px2rem(8px) px2rem(19px) px2rem(1px) rgba(0, 0, 0, 0.06);
      border-radius: px2rem(20px);
      padding-bottom: px2rem(54px);
      img {
        width: 100%;
        margin-bottom: px2rem(40px);
      }
      .content-title {
        width: px2rem(108px);
        height: px2rem(40px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 px2rem(53px);
        margin-bottom: px2rem(20px);
        background: linear-gradient(179deg, #dbf5eb 0%, #f6fffc 100%);
        border-radius: px2rem(10px);
        span {
          font-size: px2rem(24px);
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #00ce7c;
        }
      }
      .content-wrapper {
        margin: 0 px2rem(53px);
        margin-bottom: px2rem(90px);
        position: relative;
        p {
          text-indent: 2em;
          margin: 0;
          font-size: px2rem(26px);
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }
      .content-wrapper:after {
        content: "";
        position: absolute;
        bottom: px2rem(-48px);
        left: 0;
        right: 0;
        height: px2rem(1px);
        background: rgba(230, 230, 230, 0.8);
      }
      .bottom-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 px2rem(53px);
        .bottom-left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1;
          p {
            font-size: px2rem(24px);
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #00ce7c;
            line-height: px2rem(44px);
          }
        }
        .bottom-right {
          width: px2rem(154px);
          height: px2rem(154px);
          flex-shrink: 0;
          border: px2rem(2px) solid rgba(150, 229, 182, 1);
          box-sizing: border-box;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .button-wrapper {
    margin: 0 px2rem(30px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: px2rem(32px);
    img {
      width: 100%;
    }
  }
  .cover-wrapper::-webkit-scrollbar {
    width: 0 !important;
  }
}
.playbill-wrapper {
  width: px2rem(694px);
  height: px2rem(1214px);
  box-sizing: border-box;
  background: url("#{$hotspotEnv}34/share_bg.png");
  background-size: 100% 100%;
  padding: px2rem(64px) px2rem(38px) px2rem(42px) px2rem(38px);
  overflow: hidden;
  .playbill-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #ffffff;
    // box-shadow: 0 px2rem(14px) px2rem(20px) px2rem(1px) rgba(149, 225, 175, 0.5);
    border-radius: px2rem(20px);
    padding: px2rem(24px);
    .playbill-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: px2rem(32px);
      position: relative;
      span {
        flex: 1;
        font-size: px2rem(26px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #00ce7c;
      }
      img {
        flex-shrink: 0;
        width: px2rem(213px);
        height: px2rem(11px);
      }
    }
    .playbill-title:after {
      background: rgba(193, 246, 231, 1);
      content: "";
      position: absolute;
      bottom: px2rem(-18px);
      left: 0;
      right: 0;
      height: px2rem(1px);
    }
    .playbill-image {
      width: 100%;
      height: px2rem(680px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: px2rem(31px);
      overflow: hidden;
      img {
        height: 100%;
      }
    }
    .playbill-content {
      width: 100%;
      height: px2rem(77px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: px2rem(51px);
      position: relative;
      .content-left {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          height: px2rem(68px);
          width: px2rem(68px);
          border-radius: 50%;
          margin-right: px2rem(15px);
          flex-shrink: 0;
        }
        .content-left-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          span {
            font-size: px2rem(20px);
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
          span:first-child {
            font-size: px2rem(26px);
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            margin-bottom: px2rem(8px);
          }
        }
      }
      .content-right {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: px2rem(24px);
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          font {
            font-size: px2rem(44px);
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #00ce7c;
            margin: 0 px2rem(5px);
          }
        }
      }
    }
    .playbill-content:after {
      bottom: px2rem(-27px);
      background: rgba(193, 246, 231, 1);
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: px2rem(1px);
    }
    .playbill-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bottom-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1;
        p {
          font-size: px2rem(24px);
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #00ce7c;
          line-height: px2rem(44px);
        }
      }
      .bottom-right {
        width: px2rem(154px);
        height: px2rem(154px);
        flex-shrink: 0;
        border: px2rem(2px) solid rgba(150, 229, 182, 1);
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.playbill-final {
  width: px2rem(694px);
  height: px2rem(1214px);
  box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
  }
}
.image-save-wrapper-bg {
  width: 100%;
  height: 100%;
  margin-top: px2rem(150px);
}
.image-save-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: px2rem(606px);
  height: px2rem(88px);
  background: linear-gradient(136deg, #1ee1a4 0%, #58e0c4 100%);
  box-shadow: 0 px2rem(14px) px2rem(20px) px2rem(1px) rgba(149, 225, 175, 0.35);
  border-radius: px2rem(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    font-size: px2rem(32px);
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
