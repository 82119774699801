
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";

@Options({
  components: {
    ...componentAnswerCommon,
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      rank: "-",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const _this = this;
      _this.$http.get("/question/before_start").then((res: any) => {
        if (res.code === 200) {
          _this.rank = res.data.top;
        }
      });
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    close() {
      this.$emit("close");
    },
    goRule() {
      this.$emit("action", {
        type: 1,
        url: `AnswerRule`,
      });
    },
    goDraw() {
      this.$emit("action", {
        type: 1,
        url: `DrawIndex`,
      });
    },
    goAnswer() {
      this.$emit("action", {
        type: 1,
        url: `DailyAnswer`,
      });
    },
    goRank() {
      this.$emit("action", {
        type: 1,
        url: `Rank`,
      });
    },
  },
})
export default class Hotspot6 extends Vue {}
