
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

import HotspotContentList from "@/components/Common/HotspotContentList.vue";

@Options({
  components: {
    HotspotContentList,
  },
  props: {
    modules: Object,
  },
  data() {
    return {};
  },
  inject: ["outsideIframeShow"],
  methods: {
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    goZsPage() {
      const _this = this;
      const token = _this.$storage.getToken(); // || GetQueryString(ZS_TOKEN);
      const accountInfo = _this.$storage.getAccountInfo();
      if (token && accountInfo && accountInfo[_this.$storage.ACCOUNT_ID]) {
        _this.$storage.goZsAppPage(_this, 7);
      } else {
        _this.$storage.goZsAppPage(_this, 5);
      }
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
  },
})
export default class Hotspot6 extends Vue {}
