<template>
  <div class="pop-background" v-if="show">
    <div
      :class="
        'pop-wrapper ' +
        (spotData.redTitle ? 'red' : '') +
        (spotData.modules &&
        spotData.modules.message &&
        !messageBackgroundChange
          ? 'message'
          : '')
      "
    >
      <div class="pop-wrapper-background-back">
        <div class="back-left-top"></div>
        <div class="back-left-top-line"></div>
        <div class="top-line"></div>
        <div class="back-right-top-line"></div>
      </div>
      <div class="pop-wrapper-background-top">
        <div class="red-bg"></div>
        <div class="red-ornament"></div>
      </div>
      <div class="pop-container">
        <div class="pop-close-container" @click="popClose"></div>
        <div
          v-if="!(spotData.modules && spotData.modules.message)"
          :class="
            'pop-sound-container ' +
            (audioBackgroundPlaying ? 'sound-on' : 'sound-off')
          "
          @click="popSoundAction"
        ></div>
        <div v-if="spotData">
          <div class="title-container" v-if="spotData.title">
            <div class="left-container" v-if="spotData.title.length > 0">
              <span>{{ spotData.title[0] }}</span>
            </div>
            <div class="right-container">
              <span v-if="spotData.title.length > 1">{{
                spotData.title[1]
              }}</span>
              <span v-if="spotData.title.length > 2">{{
                spotData.title[2]
              }}</span>
            </div>
          </div>
          <div class="content-container">
            <div class="sub-title-container" v-if="spotData.subtitle">
              <span>{{ spotData.subtitle }}</span>
            </div>
            <div
              class="intro-container"
              v-if="spotData.intro && spotData.intro.length > 0"
            >
              <div v-for="(item, index) in spotData.intro" :key="index">
                <ImgContainer
                  v-if="typeof item === 'object'"
                  style="width: 100%"
                  :src="item.img"
                />
                <div v-else v-html="item"></div>
              </div>
            </div>
            <div
              class="module-container"
              v-if="spotData.moduleList && spotData.moduleList.length > 0"
            >
              <div
                class="module-item"
                v-for="(item, index) in spotData.moduleList"
                :key="index"
              >
                <div class="module-title" v-if="item.title">
                  <span>{{ item.title }}</span>
                </div>
                <div class="module-title-center" v-if="item.titleCenter">
                  <span>{{ item.titleCenter }}</span>
                </div>
                <div class="module-intro" v-if="item.intro">
                  <div v-html="item.intro"></div>
                </div>
                <div class="module-image" v-if="item.img">
                  <ImgContainer v-for="(i, k) in item.img" :key="k" :src="i" />
                </div>
                <div class="module-video" v-if="item.video">
                  <video
                    :src="item.video"
                    controls="controls"
                    preload="auto"
                    x5-playsinline="true"
                    playsinline="true"
                    webkit-playsinline="true"
                  />
                </div>
                <div
                  class="module-swiper"
                  v-if="item.swiper && item.swiper.length > 0"
                >
                  <div
                    :class="
                      'swiper-slide-button-prev swiper-slide-button-prev-' +
                      index
                    "
                  ></div>
                  <div class="swiper-slide-wrapper">
                    <swiper
                      @swiper="onSwiper"
                      @slideChange="onSlideChange"
                      :modules="modules"
                      :loop="true"
                      :autoHeight="true"
                      :navigation="{
                        nextEl: '.swiper-slide-button-next-' + index,
                        prevEl: '.swiper-slide-button-prev-' + index,
                      }"
                      :autoplay="{
                        delay: 3000,
                      }"
                      v-if="item.swiper.length > 0"
                    >
                      <swiper-slide v-for="(i, k) in item.swiper" :key="k">
                        <div class="swiper-silde-container">
                          <ImgContainer :src="i" />
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                  <div
                    :class="
                      'swiper-slide-button-next swiper-slide-button-next-' +
                      index
                    "
                  ></div>
                </div>
                <div
                  class="module-swiper"
                  v-if="item.swiperVideo && item.swiperVideo.length > 0"
                >
                  <div
                    :class="
                      'swiper-slide-button-prev swiper-slide-button-prev-video-' +
                      index
                    "
                  ></div>
                  <div class="swiper-slide-wrapper">
                    <swiper
                      @swiper="onSwiper"
                      @slideChange="onSlideChange"
                      :modules="modules"
                      :loop="true"
                      :autoHeight="true"
                      :navigation="{
                        nextEl: '.swiper-slide-button-next-video-' + index,
                        prevEl: '.swiper-slide-button-prev-video-' + index,
                      }"
                      v-if="item.swiperVideo.length > 0"
                    >
                      <swiper-slide v-for="(i, k) in item.swiperVideo" :key="k">
                        <div class="swiper-silde-container">
                          <video
                            :src="i"
                            controls="controls"
                            preload="auto"
                            x5-playsinline="true"
                            playsinline="true"
                            webkit-playsinline="true"
                          />
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                  <div
                    :class="
                      'swiper-slide-button-next swiper-slide-button-next-video-' +
                      index
                    "
                  ></div>
                </div>
                <div class="module-describe" v-if="item.describe">
                  <span>{{ item.describe }}</span>
                </div>
                <div class="module-describe-green" v-if="item.describeGreen">
                  <div class="icon-front-container">
                    <div class="icon-left-shade"></div>
                    <div class="icon-right-shade"></div>
                  </div>
                  <span>{{ item.describeGreen }}</span>
                </div>
              </div>
            </div>
            <div class="images-container" v-if="spotData.images">
              <ImgContainer
                v-for="(i, k) in spotData.images"
                :key="k"
                :src="i"
              />
            </div>
            <component
              :is="spotComponent"
              :modules="spotData.modules"
              @popclose="popClose"
              @audiobgstop="popSoundStop"
              @audioplay="popAudioPlay"
              @audiostop="popAudioStop"
              @bgchange="messageBgChange"
              @bginit="messageBgInit"
            ></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import { Swiper, SwiperSlide, useSwiper } from "swiper/vue/swiper-vue";
import { Autoplay, Navigation } from "swiper";
import component from "@/assets/js/component";
// import componentJsHotspot from "@/assets/js/componentJsHotspot";
import "swiper/swiper.min.css";
@Options({
  components: {
    Swiper,
    SwiperSlide,
    ...component,
  },
  props: {
    spot: String,
    spotData: Object,
    spotComponent: String,
    show: Boolean,
    audioBackgroundPlaying: Boolean,
  },
  data() {
    return {
      modules: [Navigation, Autoplay],
      swiper: useSwiper(),
      messageBackgroundChange: false,
    };
  },
  methods: {
    messageBgChange(){
      this.messageBackgroundChange = true;
    },
    messageBgInit(){
      this.messageBackgroundChange = false;
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      // console.log("slide change");
    },
    popClose() {
      this.$emit("close");
    },
    popSoundAction() {
      this.$emit("audioaction", !this.audioBackgroundPlaying);
      // this.isSounding = !this.isSounding;
    },
    popSoundStop() {
      this.$emit("audioaction", false);
    },
    popAudioPlay(e){
      this.$emit("audiosingleplay", e);
    },
    popAudioStop() {
      this.$emit("audiostop", false);
    },
  },
})
export default class HotspotPopContainer extends Vue {
}
</script>

<style lang="scss" scoped>
@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pop-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(83, 83, 83, 30%);
  backdrop-filter: blur(5px);
  z-index: 12;
  .pop-wrapper {
    position: absolute;
    top: px2rem(110px);
    bottom: px2rem(110px);
    left: px2rem(15px);
    right: px2rem(15px);
    box-sizing: border-box;
    padding: px2rem(10px);
    background: linear-gradient(
      180deg,
      #c2ead9 0%,
      rgba(210, 248, 229, 0.49) 100%
    );
    border-radius: px2rem(40px);
    border: px2rem(1px) solid #ffffff;
    .pop-wrapper-background-back {
      z-index: 3;
      position: absolute;
      top: px2rem(187px);
      bottom: px2rem(15px);
      left: px2rem(15px);
      right: px2rem(15px);
      box-sizing: border-box;
      background: url("../assets/hotspot/bg.png");
      background-size: 100% 100%;
      border-radius: 0 px2rem(90px) px2rem(40px) px2rem(40px);
      box-shadow: 0px px2rem(-2px) px2rem(20px) px2rem(1px) rgba(0, 0, 0, 0.12);
      .back-left-top {
        width: px2rem(43px);
        height: px2rem(43px);
        position: absolute;
        left: 0;
        top: px2rem(-43px);
        background: url("../assets/hotspot/SubtractLeft.png");
        background-size: 100% 100%;
      }
      .back-left-top-line {
        z-index: 1;
        width: px2rem(44px);
        height: px2rem(50px);
        position: absolute;
        left: 0;
        top: px2rem(-70px);
        background: url("../assets/hotspot/IntersectLeftWhite.png");
        background-size: 100% 100%;
      }
      .top-line {
        position: absolute;
        top: px2rem(-24px);
        left: px2rem(44px);
        right: px2rem(84px);
        height: 0;
        // box-shadow: 0 px2rem(3px) px2rem(6px) px2rem(1px) rgba(51, 51, 51, 0.2);
        opacity: 1;
        border: px2rem(2px) solid;
        border-image: radial-gradient(
            circle,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0.5)
          )
          2 2;
      }
      .back-right-top-line {
        z-index: 1;
        width: px2rem(84px);
        height: px2rem(57px);
        position: absolute;
        right: 0;
        top: px2rem(-24px);
        background: url("../assets/hotspot/IntersectRightWhite.png");
        background-size: 100% 100%;
      }
    }
    .pop-wrapper-background-top {
      z-index: 2;
      position: absolute;
      top: px2rem(15px);
      height: px2rem(257px);
      left: px2rem(15px);
      right: px2rem(15px);
      background: linear-gradient(90deg, #c1e4ed 0%, #c6f5dd 53%, #eaf9f6 100%);
      // box-shadow: inset 0 0 px2rem(30px) px2rem(1px) #b1d6cf;
      border-radius: px2rem(40px) px2rem(40px) 0 0;
      border: px2rem(1px) solid #ffffff;
    }
    .pop-container {
      z-index: 4;
      position: absolute;
      top: px2rem(15px);
      bottom: px2rem(15px);
      // bottom: 0;
      left: px2rem(15px);
      right: px2rem(15px);
      // max-height: px2rem(1403px);
      overflow-y: hidden;
      box-sizing: border-box;
      border-radius: px2rem(40px);
      .pop-close-container {
        position: absolute;
        top: px2rem(25px);
        right: px2rem(31px);
        width: px2rem(56px);
        height: px2rem(56px);
        background: url("../assets/hotspot/pop-close.png");
        background-size: 100% 100%;
      }
      .pop-sound-container {
        position: absolute;
        top: px2rem(100px);
        right: px2rem(28px);
        width: px2rem(64px);
        height: px2rem(64px);
      }
      .pop-sound-container.sound-on {
        background: url("../assets/hotspot/sound_on.png");
        background-size: 100% 100%;
        // animation: rotating 2s ease-in-out;
        // animation-iteration-count: 99;
      }
      .pop-sound-container.sound-off {
        background: url("../assets/hotspot/sound_off.png");
        background-size: 100% 100%;
      }
      .title-container {
        width: 100%;
        margin-top: px2rem(40px);
        padding: 0 px2rem(31px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .left-container {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: px2rem(16px);
          span {
            font-size: px2rem(56px);
            font-family: zihun100hao-fangfangxianfengti-Regular,
              zihun100hao-fangfangxianfengti;
            font-weight: 400;
            color: #212e42;
          }
        }
        .right-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          span:first-child {
            font-size: px2rem(20px);
            font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
            font-weight: 800;
            color: #ffffff;
            letter-spacing: px2rem(4px);
            text-shadow: 0 px2rem(2px) px2rem(8px) #68d3a8;
          }
          span:last-child {
            font-size: px2rem(20px);
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: rgba(33, 46, 66, 0.5);
            letter-spacing: px2rem(1px);
          }
        }
      }
      .content-container {
        position: absolute;
        top: px2rem(191px);
        // bottom: px2rem(40px);
        bottom: px2rem(30px);
        width: 100%;
        overflow-y: auto;
        box-sizing: border-box;
        border-radius: 0 px2rem(60px) 0 0;
        // padding-bottom: px2rem(30px);
        .images-container {
          width: 100%;
          box-sizing: border-box;
          padding: 0 px2rem(30px);
          img {
            width: 100%;
            margin-bottom: px2rem(15px);
            box-sizing: border-box;
          }
        }
        .sub-title-container {
          width: 100%;
          height: px2rem(48px);
          padding: 0 px2rem(57px);
          display: flex;
          box-sizing: border-box;
          span {
            font-size: px2rem(32px);
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #212e42;
          }
        }
        .intro-container {
          width: 100%;
          margin-top: px2rem(15px);
          padding: 0 px2rem(57px);
          box-sizing: border-box;
          :deep(p) {
            font-size: px2rem(29px);
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: px2rem(40px);
          }
          :deep(img) {
            width: 100%;
            border-radius: px2rem(18px);
            background: #96e5b6;
            padding: px2rem(4px);
            box-sizing: border-box;
          }
        }
        .module-container {
          width: 100%;
          padding: 0 px2rem(57px);
          margin-top: px2rem(43px);
          box-sizing: border-box;
          .module-item {
            width: 100%;
            box-sizing: border-box;
            margin-top: px2rem(43px);
            .module-title {
              padding-left: px2rem(18px);
              position: relative;
              display: flex;
              align-items: center;
              span {
                font-size: px2rem(29px);
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #3cab7e;
              }
            }
            .module-title:before {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 50%;
              margin-top: px2rem(-12px);
              width: px2rem(8px);
              height: px2rem(28px);
              background: linear-gradient(180deg, #0bb87a 0%, #3ddc6f 100%);
            }
            .module-title-center {
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                font-size: px2rem(29px);
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #3cab7e;
                position: relative;
              }
              span:before {
                content: "";
                display: block;
                position: absolute;
                left: px2rem(-20px);
                top: 50%;
                margin-top: px2rem(-12px);
                width: px2rem(8px);
                height: px2rem(28px);
                background: linear-gradient(180deg, #0bb87a 0%, #3ddc6f 100%);
              }
              span:after {
                content: "";
                display: block;
                position: absolute;
                right: px2rem(-20px);
                top: 50%;
                margin-top: px2rem(-12px);
                width: px2rem(8px);
                height: px2rem(28px);
                background: linear-gradient(180deg, #0bb87a 0%, #3ddc6f 100%);
              }
            }
            .module-intro {
              width: 100%;
              margin-top: px2rem(16px);
              box-sizing: border-box;
              :deep(p) {
                font-size: px2rem(29px);
                font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                line-height: px2rem(40px);
              }
              :deep(img) {
                width: 100%;
                border-radius: px2rem(18px);
                background: #96e5b6;
                padding: px2rem(4px);
                box-sizing: border-box;
              }
            }
            .module-image {
              width: 100%;
              margin-top: px2rem(32px);
              box-sizing: border-box;
              img {
                width: 100%;
                margin-bottom: px2rem(15px);
                border-radius: px2rem(18px);
                background: #96e5b6;
                padding: px2rem(4px);
                box-sizing: border-box;
              }
              img:last-child {
                margin-bottom: 0;
              }
            }
            .module-video {
              width: 100%;
              margin-top: px2rem(16px);
              box-sizing: border-box;
              // height: px2rem(371px);
              padding: px2rem(12px);
              // background: url("../assets/hotspot/video-background.png");
              // background-size: 100% 100%;
              video {
                width: 100%;
                // height: 100%;
                border-radius: px2rem(18px);
                background: #96e5b6;
                padding: px2rem(4px);
                box-sizing: border-box;
              }
            }
            .module-swiper {
              width: 100%;
              // height: px2rem(369px);
              margin-top: px2rem(32px);
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              align-content: center;
              padding: 0 px2rem(77px);
              position: relative;
              .swiper-slide-button-prev {
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: px2rem(-24px);
                width: px2rem(48px);
                height: px2rem(48px);
                margin-right: px2rem(5px);
                background: url("../assets/hotspot/swiper-button-left.png");
                background-size: 100% 100%;
              }
              .swiper-slide-wrapper {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                .swiper-silde-container {
                  width: 100%;
                  height: 100%;
                  box-sizing: border-box;
                  padding: px2rem(12px);
                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: px2rem(18px);
                    background: #96e5b6;
                    padding: px2rem(4px);
                    box-sizing: border-box;
                  }
                  video {
                    width: 100%;
                    height: 100%;
                    border-radius: px2rem(18px);
                    background: #96e5b6;
                    padding: px2rem(4px);
                    box-sizing: border-box;
                  }
                }
              }
              .swiper-slide-button-next {
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: px2rem(-24px);
                width: px2rem(48px);
                height: px2rem(48px);
                margin-left: px2rem(5px);
                background: url("../assets/hotspot/swiper-button-right.png");
                background-size: 100% 100%;
              }
            }
            .module-describe {
              width: 100%;
              margin-top: px2rem(16px);
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                font-size: px2rem(24px);
                font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                font-weight: 400;
                color: #666666;
              }
            }
            .module-describe-green {
              width: 100%;
              margin-top: px2rem(16px);
              display: flex;
              justify-content: center;
              align-items: center;
              .icon-front-container {
                width: px2rem(25px);
                height: px2rem(22px);
                background: linear-gradient(180deg, #0bb87a 0%, #3ddc6f 100%);
                box-shadow: 0px px2rem(14px) px2rem(20px) px2rem(1px)
                  rgba(149, 225, 175, 0.5);
                position: relative;
                .icon-left-shade {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 0;
                  height: 0;
                  border-top: px2rem(22px) solid #fcfcfd;
                  border-right: px2rem(12.5px) solid transparent;
                }
                .icon-right-shade {
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 0;
                  height: 0;
                  border-top: px2rem(22px) solid #fcfcfd;
                  border-left: px2rem(12.5px) solid transparent;
                }
              }
              span {
                font-size: px2rem(24px);
                font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                font-weight: 400;
                color: #3cab7e;
              }
            }
          }
        }
      }
      .content-container::-webkit-scrollbar {
        width: 0 !important;
      }
      @media screen and (min-width: 390px) {
        .title-container {
          margin-top: px2rem(57px);
        }
      }
    }
  }
  .pop-wrapper.red {
    .pop-wrapper-background-top {
      background: linear-gradient(255deg, #d42a2a 0%, #ffc2c2 100%);
      .red-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: px2rem(542.11px);
        height: px2rem(166px);
        background: url("../assets/hotspot/title_red_bg.png");
        background-size: 100% 100%;
        z-index: 1;
      }
      .red-ornament {
        position: absolute;
        top: 0;
        right: px2rem(120px);
        width: px2rem(168.23px);
        height: px2rem(166px);
        background: url("../assets/hotspot/title_red_ornament.png");
        background-size: 100% 100%;
        z-index: 2;
      }
    }
    .pop-container {
      .content-container {
        .intro-container {
          :deep(img) {
            background: linear-gradient(#ffb7b7, #ffb68c);
          }
        }
        .module-container {
          .module-item {
            .module-title {
              span {
                color: #212e42;
                font-weight: bold;
              }
            }
            .module-title::before {
              background: #da3b3b;
            }
            .module-image {
              img {
                background: linear-gradient(#ffb7b7, #ffb68c);
              }
            }
            .module-video {
              video {
                background: linear-gradient(#ffb7b7, #ffb68c);
              }
            }
            .module-intro {
              :deep(img) {
                background: linear-gradient(#ffb7b7, #ffb68c);
              }
            }
            .module-swiper {
              .swiper-slide-button-prev {
                background: url("../assets/hotspot/swiper-button-left.png");
                background-size: 100% 100%;
              }
              .swiper-slide-button-next {
                background: url("../assets/hotspot/swiper-button-right.png");
                background-size: 100% 100%;
              }
              .swiper-slide-wrapper {
                .swiper-silde-container {
                  img {
                    background: linear-gradient(#ffb7b7, #ffb68c);
                  }
                }
              }
            }
          }
        }
      }
      .title-container {
        .left-container {
          span {
            color: #ffd953;
          }
        }
        .right-container {
          span:first-child {
            text-shadow: initial;
          }
        }
      }
    }
  }
  .pop-wrapper.message {
    background: url(#{$hotspotEnv}33.5/message_bg.png);
    background-size: 100% 100%;
    border: 0;
    .pop-wrapper-background-back {
      display: none;
    }
    .pop-wrapper-background-top {
      display: none;
    }
    .pop-container {
      .title-container {
        .left-container {
          span {
            color: #ffffff;
          }
        }
        .right-container {
          span:last-child {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
  @media screen and (min-width: 600px) {
    .pop-wrapper {
      // width: px2rem(2560px);
      width: px2rem(840px);
      right: initial;
      left: 50%;
      // margin-left: px2rem(-1280px);
      margin-left: px2rem(-395px);
      overflow: hidden;
      .pop-close-container {
        top: px2rem(40px);
        right: px2rem(70px);
      }
      .pop-sound-container {
        top: px2rem(115px);
        right: px2rem(70px);
      }
      .title-container {
        margin-top: px2rem(80px);
        padding: 0 px2rem(50px);
      }
      .content-container {
        bottom: px2rem(100px);
        width: initial;
        left: 0;
        right: 0;
        // padding-left: px2rem(40px);
        // padding-right: px2rem(40px);
        // margin-right: -20px;
        margin-bottom: -20px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1240px) {
  .pop-wrapper {
    top: px2rem(40px) !important;
    bottom: px2rem(40px) !important;
  }
}
</style>
