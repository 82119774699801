
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import { getFirstFramesByVideos, judgeMobile } from "@/assets/js/utils";
import HotspotSwiper from "@/components/Common/HotspotSwiper.vue";
import HotspotContentList from "@/components/Common/HotspotContentList.vue";

@Options({
  components: {
    HotspotSwiper,
    HotspotContentList,
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      list: [],
      videoShow: false,
      video: ``,
      poster: ``,
      popStyle: ` position: fixed; overflow-y: hidden;${
        judgeMobile().mobile ? "width:90%;" : "height:90%;"
      }`,
    };
  },
  mounted() {
    // getFirstFramesByVideos(this.list, this.modules.videos);
  },
  methods: {
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    handleVideoPlay() {
      this.$emit("audiobgstop");
    },
    watchVideo(i: any) {
      const _this = this;
      _this.poster = i.poster;
      _this.video = i.video;
      _this.$nextTick(() => {
        _this.videoShow = true;
      });
    },
    videoPause() {
      if (this.$refs.popvideo) {
        this.$refs.popvideo.pause();
      }
    },
  },
})
export default class Hotspot6 extends Vue {}
