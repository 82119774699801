
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    modules: Object,
  },
  methods: {
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    handleVideoPlay() {
      this.$emit("audiobgstop");
    },
  },
})
export default class CultureContainer extends Vue {}
