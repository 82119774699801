
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    text: String,
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      isZsApp: false,
    };
  },
  inject: ["outsideIframeShow"],
  created() {
    this.isZsApp = this.$storage.isZsApp();
  },
  methods: {
    goDownload() {
      this.$storage.goZsAppPage(this);
    },
  },
})
export default class HotspotLogoTips extends Vue {}
