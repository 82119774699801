<template>
  <div class="hotspot-module-container" v-if="modules">
    <div class="cover-wrapper" v-if="!showDetail">
      <div class="list-item" v-for="(i, k) in modules.list" :key="k">
        <ImgContainer :src="i.img" />
        <div class="list-item-image-desc" @click.stop.prevent="click(k)">
          <span>{{ i.title }}</span>
        </div>
      </div>
    </div>
    <div class="detail-wrapper-bg" v-if="showDetail">
      <div class="detail-wrapper" v-if="!showMoreDetail && currentIndex === 0">
        <div class="detail-title">
          <span>{{ modules.list[currentIndex].title }}</span>
        </div>
        <div class="content-wrapper">
          <div class="list-title">
            <span>排名</span>
            <span>名人头像</span>
            <span>名称</span>
          </div>
          <div class="content-list">
            <div
              class="list-item"
              v-for="(i, k) in list"
              :key="k"
              @click.stop.prevent="clickMore(k)"
            >
              <div class="item-left-wrapper">
                <span>{{ `NO.${k + 1}` }}</span>
              </div>
              <div class="item-middle-wrapper">
                <img v-lazy="i.avatar" />
              </div>
              <div class="item-right-wrapper">
                <span>{{ i.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-wrapper" v-if="showMoreDetail && showBeauti">
        <div class="more-detail-title-wrapper">
          <span>服务之星</span>
        </div>
        <div class="more-detail-content-wrapper">
          <div class="more-detail-content-top-wrapper">
            <div class="more-detail-content-top-left">
              <ImgContainer :src="showBeautiModel.avatar" />
            </div>
            <div class="more-detail-content-top-right">
              <span>{{ showBeautiModel.name }}</span>
              <div class="location" v-if="showBeautiModel.location">
                <img :src="`${rootUrl}/hotspot/final/32/location.png`" />
                <span>{{ showBeautiModel.location }}</span>
              </div>
              <div
                class="rank"
                v-if="
                  showBeautiModel.rank && showBeautiModel.rank != 'undefined'
                "
              >
                <span>{{ `NO.${showBeautiModel.rank}` }}</span>
                <span>{{ `NO.${showBeautiModel.rank}` }}</span>
              </div>
            </div>
          </div>
          <div
            class="more-detail-content-slogan-wrapper"
            v-if="showBeautiModel.slogan"
          >
            <div class="slogan-title">
              <span>座右铭</span>
            </div>
            <div class="slogan-content">
              <p>{{ showBeautiModel.slogan }}</p>
            </div>
          </div>
          <div class="more-detail-content-desc-wrapper">
            <div class="desc-title">
              <span>详情</span>
            </div>
            <ImgContainer
              v-if="showBeautiModel.detailImg"
              :src="showBeautiModel.detailImg"
            />
            <p v-if="showBeautiModel.detailDesc">
              {{ showBeautiModel.detailDesc }}
            </p>
            <video
              v-if="showBeautiModel.detailVideo"
              :src="showBeautiModel.detailVideo"
              controls="controls"
              preload="auto"
              x5-playsinline="true"
              playsinline="true"
              webkit-playsinline="true"
            />
          </div>
        </div>
      </div>
      <div class="detail-wrapper" v-if="!showMoreDetail && currentIndex === 1">
        <div v-if="meVerify == -1" class="detail-form-wrapper">
          <div class="detail-form-item">
            <div class="detail-form-item-left">
              <span>上传头像：</span>
            </div>
            <div class="detail-form-item-right">
              <van-uploader
                :accept="`image/png, image/jpg`"
                v-model="avatarList"
                :max-count="1"
              />
            </div>
          </div>
          <div class="detail-form-item">
            <div class="detail-form-item-left">
              <span>姓名：</span>
            </div>
            <div class="detail-form-item-right">
              <input v-model="nameForm" placeholder="请输入" />
            </div>
          </div>
          <div class="detail-form-item">
            <div class="detail-form-item-left">
              <span>城市：</span>
            </div>
            <div class="detail-form-item-right">
              <input v-model="locationForm" placeholder="请输入" />
            </div>
          </div>
          <div class="detail-form-item">
            <div class="detail-form-item-left">
              <span>座右铭：</span>
            </div>
            <div class="detail-form-item-right">
              <input v-model="sloganForm" placeholder="请输入" />
            </div>
          </div>
          <div class="detail-form-item">
            <div class="detail-form-item-left">
              <span>上传图片：</span>
            </div>
            <div class="detail-form-item-right">
              <van-uploader
                :accept="`image/png, image/jpg`"
                v-model="detailImgList"
                :max-count="1"
              />
            </div>
          </div>
          <div class="detail-form-item">
            <div class="detail-form-item-left">
              <span>上传视频：</span>
            </div>
            <div class="detail-form-item-right">
              <van-uploader
                :accept="`video/mp4, video/m4v`"
                v-model="detailVideoList"
                :max-count="1"
              />
            </div>
          </div>
          <div class="detail-form-item textarea">
            <div class="detail-form-item-left">
              <span>发表文字：</span>
            </div>
            <div class="detail-form-item-right">
              <textarea
                v-model="detailDescForm"
                placeholder="这一刻的想法"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Back
      v-if="showDetail"
      @action="goBack"
      :showAction="showBeautiAction"
      :actionText="showBeautiActionText"
      :actionStyle="showBeautiActionStyle"
      @create="handleBeautiAction"
    ></Back>
    <van-popup
      round
      :style="{ position: 'fixed', overflow: 'hidden' }"
      v-model:show="isPlaybillShow"
      @closed="closePlaybill"
      teleport="#app"
    >
      <div
        class="more-detail-content-wrapper playbill-wrapper"
        ref="playbill"
        @click="createPlaybill"
        v-if="
          !isPlaybillAlready && isPlaybillShow && showMoreDetail && showBeauti
        "
      >
        <div class="more-detail-content-top-wrapper">
          <div class="more-detail-content-top-left">
            <img :src="showBeautiModelAvatarBase64" />
          </div>
          <div class="more-detail-content-top-right">
            <span>{{ showBeautiModel.name }}</span>
            <div class="location" v-if="showBeautiModel.location">
              <img
                :src="`${rootUrl}/hotspot/final/32/location.png`"
                crossOrigin="anonymous"
              />
              <span>{{ showBeautiModel.location }}</span>
            </div>
            <div
              class="rank"
              v-if="showBeautiModel.rank && showBeautiModel.rank != 'undefined'"
            >
              <span>{{ `NO.${showBeautiModel.rank}` }}</span>
              <span>{{ `NO.${showBeautiModel.rank}` }}</span>
            </div>
          </div>
        </div>
        <div
          class="more-detail-content-slogan-wrapper"
          v-if="showBeautiModel.slogan"
        >
          <div class="slogan-title">
            <span>座右铭</span>
          </div>
          <div class="slogan-content">
            <p>{{ showBeautiModel.slogan }}</p>
          </div>
        </div>
        <div class="more-detail-content-desc-wrapper">
          <div class="desc-title">
            <span>详情</span>
          </div>
          <img
            v-if="showBeautiModelDetailImgBase64"
            :src="showBeautiModelDetailImgBase64"
          />
          <p v-if="showBeautiModel.detailDesc">
            {{ showBeautiModel.detailDesc }}
          </p>
        </div>
      </div>
      <div
        class="playbill-final"
        v-if="isPlaybillAlready && isPlaybillShow"
        @click="createPlaybill"
      >
        <img :src="playbillBase64" />
      </div>
    </van-popup>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

import Back from "@/components/Common/Back.vue";
import HotspotContentList from "@/components/Common/HotspotContentList.vue";
import html2canvas from 'html2canvas'

@Options({
  components: {
    Back,
    HotspotContentList,
    html2canvas,
  },
  props: {
    modules: Object,
  },
  data() {
    const rootUrl = process.env.VUE_APP_DEPLOY_URL;
    return {
      popShow: false,
      popData: {},
      currentIndex: -1,
      showDetail: false,
      showMoreDetail: false,
      currentListIndex: -1,
      isPlaybillShow: false,
      playbillBase64: '',
      isPlaybillAlready: false,
      isGetPlaybillImageFinished: false,
      loadingToast: {},
      meVerify: -1,
      nameForm: '',
      sloganForm: '',
      locationForm: '',
      detailDescForm: '',
      avatarList: [],
      detailImgList: [],
      detailVideoList: [],
      showBeauti: false,
      showBeautiModel: {},
      showBeautiAction: false,
      showBeautiActionText: '',
      showBeautiActionStyle: '',
      list: [],
      rootUrl: rootUrl,
      showBeautiModelAvatarBase64: '',
      showBeautiModelDetailImgBase64: '',
    };
  },
  created() {
    this.initInfo()
  },
  methods: {
    initInfo(callback){
      this.$http.get("/account/info").then((res) => {
        if (res.code === 200) {
          this.$storage.setAccountInfo(res.data);
          this.initBeautiModel()
          callback && callback()
        }
      });
      this.$http.get("/service-star/page-list", {
        per_page: 100
      }).then((res) => {
        if (res.code === 200) {
          // console.log(res, 'res')
          this.list = res.data.data.map((item, index)=>{
            return {
              avatar: item.avatar,
              name: item.name,
              slogan: item.slogan,
              location: item.location,
              detailImg: item.detail_img,
              detailDesc: item.detail_desc,
              detailVideo: item.detail_video,
              rank: index+1
            }
          })
        }
      });
    },
    initBeautiModel(){
      const userInfo = this.$storage.getAccountInfo();
        if(userInfo[this.$storage.ACCOUNT_IS_SERVICE_STAR] == '1'){
          this.showBeautiModel = {
            name: userInfo[this.$storage.ACCOUNT_SERVICE_NAME],
            avatar: userInfo[this.$storage.ACCOUNT_SERVICE_AVATAR],
            location: userInfo[this.$storage.ACCOUNT_SERVICE_LOCATION],
            slogan: userInfo[this.$storage.ACCOUNT_SERVICE_SLOGAN],
            detailDesc: userInfo[this.$storage.ACCOUNT_SERVICE_DETAIL_DESC],
            detailVideo: userInfo[this.$storage.ACCOUNT_SERVICE_DETAIL_VIDEO],
            detailImg: userInfo[this.$storage.ACCOUNT_SERVICE_DETAIL_IMG],
            sort: userInfo[this.$storage.ACCOUNT_SERVICE_SORT],
            status: userInfo[this.$storage.ACCOUNT_SERVICE_STATUS],
            rank: userInfo[this.$storage.ACCOUNT_SERVICE_RANK],
          }
        }
    },
    // handleUpload(type, file){
    //   // console.log(type, file);
    // },
    async uploadFile(file){
      const form = new FormData();
      form.append("file",file);
      return await this.$http.uploads("/common/upload", form)
    },
    handleBeautiAction(){
      if(this.currentIndex === 1 && !this.showBeauti){
        this.doCreate()
      }else if(this.showBeauti){
        if(this.showBeautiModel.status == '0'){
          this.$toast({
            message: '审核中...请耐心等待哟~~',
            duration: 1000,
            forbidClick: true,
          });
        }else if(this.showBeautiModel.status == '1'){
          this.showPlaybill()
        }else if(this.showBeautiModel.status == '2'){
          this.showBeauti = false
          this.showMoreDetail = false
          this.updateActionText();
          this.nameForm = this.showBeautiModel.name;
          this.sloganForm = this.showBeautiModel.slogan;
          this.locationForm = this.showBeautiModel.location;
          this.detailDescForm = this.showBeautiModel.detailDesc;
          this.avatarList = [];
          this.detailVideoList = [];
          this.detailImgList = [];
        }
      }
    },
    doCreate(){
      const _this = this
      if(!_this.nameForm){
        _this.$toast({
          message: '姓名必填',
        });
        return;
      }
      if(!_this.avatarList || _this.avatarList.length == 0){
        _this.$toast({
          message: '头像必填',
        });
        return;
      }
      _this.loadingToast = _this.$toast.loading({
        message: '上传资料中...',
        duration: 0,
        forbidClick: true,
        overlay: true,
      });
      let avatarPromise = Promise.resolve('');
      if(_this.avatarList.length > 0){
        avatarPromise = this.uploadFile(_this.avatarList[0].file)
      }
      let detailImgPromise = Promise.resolve('');
      if(_this.detailImgList.length > 0){
        detailImgPromise = this.uploadFile(_this.detailImgList[0].file)
      }
      let detailVideoPromise = Promise.resolve('');
      if(_this.detailVideoList.length > 0){
        detailVideoPromise = this.uploadFile(_this.detailVideoList[0].file)
      }
      const promises = [avatarPromise, detailImgPromise, detailVideoPromise];
      Promise.all(promises).then(values=>{
        const requestParam = {
          name: _this.nameForm,
          slogan: _this.sloganForm,
          location: _this.locationForm,
          detail_desc: _this.locationForm,
        }
        if(values[0] && values[0].data.code === 200 && values[0].data.data.url){
          requestParam.avatar = values[0].data.data.url;
        }
        if(values[1] && values[1].data.code === 200 && values[1].data.data.url){
          requestParam.detail_img = values[1].data.data.url;
        }
        if(values[2] && values[2].data.code === 200 && values[2].data.data.url){
          requestParam.detail_video = values[2].data.data.url;
        }
        this.$http.post("/service-star/create", requestParam).then(res=>{
          if (res.code === 200) {
            _this.loadingToast.clear()
            _this.$toast({
              message: '提交成功',
              duration: 1000,
            });
            setTimeout(() => {
              _this.initInfo()
              _this.updateActionText()
              _this.showMoreDetail = false
              _this.showDetail = false
              _this.showBeauti = false
              _this.currentIndex = -1
            }, 600);
          } else {
            _this.$toast({
              message: res.msg,
              duration: 1000,
            });
          }
        });
      })
    },
    generatePlaybill(){
      const _this = this
      // 生成头像/图片base64解决跨域问题
      let showBeautiModelAvatarPromise = Promise.resolve(''), showBeautiModelDetailImgPromise = Promise.resolve('');
      if(this.showBeautiModel.avatar){
        showBeautiModelAvatarPromise = this.$storage.getUrlBase64(this.showBeautiModel.avatar)
      }
      if(this.showBeautiModel.detailImg){
        showBeautiModelDetailImgPromise = this.$storage.getUrlBase64(this.showBeautiModel.detailImg)
      }
      Promise.all([showBeautiModelAvatarPromise, showBeautiModelDetailImgPromise]).then((res)=>{
        if(res[0]){
          _this.showBeautiModelAvatarBase64 = res[0]
        }
        if(res[1]){
          _this.showBeautiModelDetailImgBase64 = res[1]
        }
        _this.isPlaybillShow = true;
        _this.$nextTick(()=>{
          html2canvas(_this.$refs.playbill, {
            backgroundColor: null,
            useCORS: true,
            // allowTaint: true,
          }).then((canvas) => {
            let url = canvas.toDataURL('image/png');
            _this.playbillBase64 = url;
            _this.isPlaybillAlready = true;
            // _this.createPlaybill();
          })
        })
      })
    },
    showPlaybill(){
      const _this = this
      _this.generatePlaybill()
    },
    closePlaybill(){
      this.isPlaybillShow = false;
      this.isPlaybillAlready = false;
    },
    createPlaybill(){
      const _this = this
      const timedate = Date.parse(new Date());
      const fileName = `服务之星-海报-${timedate}`
      const form = new FormData();
      form.append("file", _this.$storage.base64ToFile(_this.playbillBase64, fileName) );
      _this.$http.uploads("/common/upload", form).then((res)=>{
        if(res.data.code === 200 && res.data.data.url){
          _this.$storage.zsImageShare(fileName, _this.playbillBase64, res.data.data.url, process.env.VUE_APP_DEPLOY_URL)
        }
      })
    },
    popClose() {
      this.popShow = false;
    },
    goBack() {
      if (this.showMoreDetail) {
        if(this.showBeauti && this.currentIndex === 1){
          this.showBeauti = false;
          this.showDetail = false;
          this.currentIndex = -1
        }
        this.showMoreDetail = false;
        this.showBeautiAction = false;
        this.initBeautiModel();
        this.updateActionText();
        return;
      }
      this.showDetail = false;
    },
    clickMore(k) {
      this.currentListIndex = k;
      this.showBeautiModel = this.list[k];
      this.showMoreDetail = true;
      this.showBeauti = true;
    },
    click(k) {
      this.currentIndex = k;
      if(k === 1){
        if(this.$storage.getAccountInfo()[this.$storage.ACCOUNT_IS_SERVICE_STAR] == '1' && this.showBeautiModel){
          this.showMoreDetail = true;
          this.showBeauti = true;
        }
        this.showBeautiAction = true;
        this.updateActionText()
        // console.log(this.showBeautiActionText, this.showBeautiActionStyle)
      }
      this.showDetail = true;
    },
    updateActionText(){
      let text = '', style = 'background:'
      // console.log(this.currentIndex, this.showBeauti, this.showBeautiModel.status)
      if(this.currentIndex === 1 && !this.showBeauti){
        text = '提交审核'
        style += 'linear-gradient(136deg, #1ee1a4 0%, #58e0c4 100%)'
      }else if(this.showBeauti){
        if(this.showBeautiModel.status == '0'){
          text = '审核中...'
          style += '#9aa094;'
        }else if(this.showBeautiModel.status == '1'){
          text = '生成海报'
          style += 'linear-gradient(136deg, #1ee1a4 0%, #58e0c4 100%)'
        }else if(this.showBeautiModel.status == '2'){
          text = '审核未通过，点击重新提交'
          style += '#9aa094;'
        }
      }
      this.showBeautiActionText = text
      this.showBeautiActionStyle = style
    },
  },
})
export default class Hotspot6 extends Vue {}
</script>

<style scoped lang="scss">
.hotspot-module-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  .cover-wrapper {
    position: absolute;
    top: px2rem(30px);
    left: 0;
    right: 0;
    margin: 0 auto;
    .list-item {
      width: 100%;
      box-sizing: border-box;
      padding: 0 px2rem(45px);
      margin-bottom: px2rem(50px);
      img {
        width: 100%;
      }
      .list-item-image-desc {
        width: 100%;
        margin-top: px2rem(12px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(136deg, #1ee1a4 0%, #58e0c4 100%);
        box-shadow: 0 px2rem(14px) px2rem(20px) px2rem(1px)
          rgba(149, 225, 175, 0.35);
        border-radius: px2rem(10px);
        box-sizing: border-box;
        padding: px2rem(22px);
        text-align: center;
        span {
          font-size: px2rem(29px);
          line-height: px2rem(45px);
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    .list-item:last-child {
      margin-bottom: 0;
    }
  }
  .detail-wrapper-bg {
    width: 100%;
    height: 89%;
    overflow-y: auto;
  }
  .detail-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: px2rem(10px);
  }
  .cover-wrapper::-webkit-scrollbar,
  .detail-wrapper-bg::-webkit-scrollbar {
    width: 0 !important;
  }
  .detail-title {
    margin: 0 px2rem(45px);
    margin-bottom: px2rem(25px);
    span {
      font-size: px2rem(32px);
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #212e42;
    }
  }
  .content-wrapper {
    margin: 0 px2rem(45px);
    background: #ffffff;
    border-radius: px2rem(20px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .content-title {
      flex-shrink: 0;
      width: 100%;
      height: px2rem(98px);
      box-sizing: border-box;
      padding: px2rem(31px) px2rem(34px);
      background: #eefaf5;
      span {
        font-size: px2rem(24px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #aaaaaa;
      }
      span.big {
        font-size: px2rem(36px);
        color: #00ce7c;
      }
    }
    .list-title {
      // width: 100%;
      flex-shrink: 0;
      margin: px2rem(29px) px2rem(24px) px2rem(29px) px2rem(24px);
      background: linear-gradient(136deg, #1ee1a4 0%, #58e0c4 100%);
      box-shadow: 0 px2rem(10px) px2rem(20px) px2rem(1px)
        rgba(149, 225, 175, 0.5);
      border-radius: px2rem(14px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: px2rem(12px) px2rem(20px);
      padding-right: px2rem(50px);
      span {
        font-size: px2rem(29px);
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
      span:first-child {
        flex-shrink: 0;
        margin-right: px2rem(120px);
      }
      span:nth-child(2) {
        flex: 1;
      }
      span:last-child {
        flex-shrink: 0;
      }
    }
    .content-list {
      flex: 1;
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: px2rem(29px) px2rem(24px);
      .list-item {
        width: 100%;
        display: flex;
        margin-bottom: px2rem(64px);
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 px2rem(31px) 0 px2rem(20px);
        height: px2rem(75px);
        position: relative;
        .item-left-wrapper {
          width: px2rem(95px);
          margin-right: px2rem(100px);
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: px2rem(29px);
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #00ce7c;
          }
        }
        .item-middle-wrapper {
          display: flex;
          flex-direction: column;
          flex: 1;
          height: 100%;
          justify-content: space-between;
          align-items: flex-start;
          img {
            width: px2rem(80px);
            height: px2rem(80px);
            box-sizing: border-box;
            border: px2rem(2px) solid #96e5b6;
            border-radius: 50%;
          }
        }
        .item-right-wrapper {
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: px2rem(29px);
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .list-item:after {
        position: absolute;
        content: "";
        bottom: px2rem(-32px);
        left: 0;
        right: 0;
        height: px2rem(1px);
        background: #e6e6e6;
      }
      .list-item:last-child {
        margin-bottom: 0;
      }
      .list-item:last-child:after {
        display: none;
      }
    }
    .content-list::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  .more-detail-title-wrapper {
    margin: 0 px2rem(45px);
    margin-top: px2rem(20px);
    margin-bottom: px2rem(38px);
    span {
      font-size: px2rem(30px);
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      position: relative;
    }
    span:before {
      content: "";
      position: absolute;
      bottom: px2rem(-10px);
      left: 0;
      right: px2rem(-20px);
      height: px2rem(3px);
      background: rgba(60, 171, 126, 0.2);
    }
    span:after {
      content: "";
      position: absolute;
      width: px2rem(29px);
      bottom: px2rem(-10px);
      right: px2rem(-20px);
      height: px2rem(3px);
      background: #00ce7c;
      z-index: 1;
    }
  }
}
.more-detail-content-wrapper {
  padding: px2rem(26px) 0 px2rem(63px) 0;
  margin: 0 px2rem(45px);
  box-sizing: border-box;
  background: #ffffff;
  border-radius: px2rem(20px);
  border: px2rem(2px) solid #96e5b6;
  .more-detail-content-top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: px2rem(132px);
    margin: px2rem(29px) 0;
    background: linear-gradient(136deg, #1ee1a4 0%, #58e0c4 100%);
    padding-left: px2rem(46px);
    .more-detail-content-top-left {
      width: px2rem(190px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      box-sizing: border-box;
      border-radius: 50%;
      background: #ffffff;
      overflow: hidden;
      padding: px2rem(4px);
      img {
        width: px2rem(180px);
        height: px2rem(180px);
        border-radius: 50%;
        border: px2rem(2px) solid #96e5b6;
      }
    }
    .more-detail-content-top-right {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-left: px2rem(50px);
      flex: 1;
      position: relative;
      height: 100%;
      span {
        font-size: px2rem(32px);
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
      .location {
        width: px2rem(100px);
        height: px2rem(36px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: px2rem(20px);
        background: rgba(0, 0, 0, 0.2);
        border-radius: px2rem(18px);
        box-sizing: border-box;
        img {
          width: px2rem(14.72px);
          height: px2rem(18.13px);
        }
        span {
          margin-left: px2rem(10px);
          font-size: px2rem(22px);
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .rank {
        width: px2rem(128px);
        height: px2rem(37px);
        background: #fff500;
        border-radius: 0 0 0 px2rem(22px);
        position: absolute;
        right: 0;
        top: px2rem(-1px);
        display: flex;
        justify-content: center;
        align-items: center;
        span:first-child {
          font-size: px2rem(33px);
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #fff500;
          position: absolute;
          top: px2rem(-24px);
        }
        span:last-child {
          font-size: px2rem(29px);
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #00ce7c;
          position: absolute;
          top: px2rem(-20px);
          z-index: 1;
        }
      }
    }
  }
  .more-detail-content-slogan-wrapper {
    margin: 0 px2rem(45px);
    margin-top: px2rem(70px);
    .slogan-title {
      width: 100%;
      span {
        width: px2rem(108px);
        height: px2rem(40px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(179deg, #dbf5eb 0%, #f6fffc 100%);
        border-radius: px2rem(10px);
        font-size: px2rem(24px);
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #00ce7c;
      }
    }
    .slogan-content {
      width: 100%;
      margin-top: px2rem(20px);
      p {
        font-size: px2rem(26px);
        margin: 0;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: px2rem(42px);
      }
    }
  }
  .more-detail-content-desc-wrapper {
    margin: 0 px2rem(45px);
    margin-top: px2rem(35px);
    .desc-title {
      width: 100%;
      margin-bottom: px2rem(20px);
      span {
        width: px2rem(108px);
        height: px2rem(40px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(179deg, #dbf5eb 0%, #f6fffc 100%);
        border-radius: px2rem(10px);
        font-size: px2rem(24px);
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #00ce7c;
      }
    }
    img {
      width: 100%;
      box-sizing: border-box;
      border-radius: px2rem(8px);
      border: px2rem(2px) solid #96e5b6;
      margin-bottom: px2rem(20px);
    }
    p {
      text-align: justify;
      text-indent: 2em;
      margin: 0;
      font-size: px2rem(26px);
      margin: 0;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: px2rem(42px);
      margin-bottom: px2rem(20px);
    }
    video {
      width: 100%;
      border-radius: px2rem(18px);
      background: #96e5b6;
      padding: px2rem(4px);
      box-sizing: border-box;
    }
  }
}
.playbill-wrapper {
  width: px2rem(628px);
}
.detail-form-wrapper {
  margin: 0 px2rem(45px);
  margin-top: px2rem(20px);
  box-sizing: border-box;
  padding: px2rem(32px) px2rem(50px);
  background: #ffffff;
  box-shadow: 0 px2rem(8px) px2rem(19px) px2rem(1px) rgba(0, 0, 0, 0.06);
  border-radius: px2rem(20px);
  .detail-form-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: px2rem(40px);
    .detail-form-item-left {
      flex-shrink: 0;
      width: px2rem(163.44px);
      span {
        font-size: px2rem(29px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
    .detail-form-item-right {
      flex: 1;
      .file {
        width: px2rem(100px);
        height: px2rem(100px);
        display: none;
      }
      img {
        width: px2rem(100px);
        height: px2rem(100px);
      }
      input {
        outline: 0;
        border: 0;
        font-size: px2rem(29px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }
      textarea {
        width: 100%;
        height: px2rem(300px);
        border: 0;
        resize: none;
        font-size: px2rem(29px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: px2rem(45px);
      }
    }
  }
  .detail-form-item.textarea {
    flex-direction: column;
    .detail-form-item-left {
      margin-bottom: px2rem(20px);
    }
    .detail-form-item-right {
      width: 100%;
    }
  }
  .detail-form-item:last-child {
    margin-bottom: 0;
  }
}
.playbill-final {
  width: px2rem(628);
  box-sizing: border-box;
  img {
    width: 100%;
  }
}
</style>
