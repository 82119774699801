<template>
  <PopInner
    :circleRight="true"
    :circleTop="true"
    :flower="true"
    :spot="true"
    :starTop="true"
    :starMiddle="true"
  >
    <div class="answer-container">
      <Back @action="goBack" />
      <Title :img="`${rootUrl}/answer/answer_over.png`" :imgWidthType="2" />
      <div :class="`cover-wrapper ${data.isCorrect ? '' : 'non-award'}`">
        <div class="point-share" @click.stop.prevent="showPlaybill">
          <span>成绩分享</span>
        </div>
        <div class="point-wrapper" v-if="data.isCorrect">
          <img :src="`${rootUrl}/answer/answer_over_point_bg.png`" />
          <span>{{ data.score }}</span>
        </div>
        <div class="name-wrapper" v-if="data.isCorrect">
          <span>称号：{{ data.rankName }}</span>
        </div>
        <div class="line-wrapper" v-if="data.isCorrect"></div>
        <div class="answer-info-wrapper" v-if="data.isCorrect">
          <img :src="`${rootUrl}/answer/answer_over_star.png`" />
          <span>答题总体数：{{ data.answerRight }}</span>
          <img :src="`${rootUrl}/answer/answer_over_star.png`" />
        </div>
        <div class="fail-wrapper" v-if="!data.isCorrect">
          <div class="fail-title">
            <span>非常遗憾</span>
            <span>本次没有获得抽奖机会</span>
          </div>
          <img :src="`${rootUrl}/answer/answer_over_fail.png`" />
        </div>
      </div>
      <div class="get-one-award" v-if="data.isCorrect">
        <span>获得一次抽奖机会</span>
      </div>
      <Action
        :text="data.isCorrect ? actionTitleActive : actionTitleDisable"
        @action="doAction"
      />
    </div>
    <van-popup
      :style="{
        position: 'fixed',
        overflow: 'hidden',
        background: 'transparent',
      }"
      v-model:show="isPlaybillShow"
      @closed="closePlaybill"
      teleport="#app"
    >
      <div
        class="playbill-wrapper"
        ref="playbill"
        @click="createPlaybill"
        v-if="!isPlaybillAlready && isPlaybillShow"
      >
        <div class="playbill-container">
          <div class="playbill-title">
            <span>“{{ name }}”</span>
          </div>
          <div class="playbill-desc">
            <span>恭喜你在瘦吧元宇宙每日知识问答中成为</span>
          </div>
          <div
            class="playbill-image"
            :style="{
              background: `url(${imgBgBase64})`,
              backgroundSize: '100% 100%',
            }"
          >
            <img :src="imgBase64" />
            <span>{{ data.rankName }}</span>
          </div>
          <div class="playbill-content">
            <div
              class="content-left"
              :style="{
                background: `url(${imgContentLeftBase64})`,
                backgroundSize: '100% 100%',
              }"
            ></div>
            <p>{{ data.share_title }}</p>
            <div
              class="content-right"
              :style="{
                background: `url(${imgContentRightBase64})`,
                backgroundSize: '100% 100%',
              }"
            ></div>
          </div>
          <div
            class="playbill-line"
            :style="{
              background: `url(${imgLineBase64})`,
              backgroundSize: '100% 100%',
            }"
          ></div>
          <div class="playbill-bottom">
            <div class="bottom-left">
              <p>我已连续答对{{ data.answerRight }}题。</p>
              <p>快来挑战我吧！</p>
              <p>扫二维码一起来刷题！</p>
            </div>
            <div class="bottom-right">
              <vue-qrcode
                :value="qrcodeValue"
                tag="img"
                :options="qrcodeOptions"
              ></vue-qrcode>
            </div>
          </div>
        </div>
      </div>
      <div
        class="playbill-final"
        v-if="isPlaybillAlready && isPlaybillShow"
        @click="createPlaybill"
      >
        <img :src="playbillBase64" />
      </div>
      <div
        class="image-save-wrapper-bg"
        v-if="isPlaybillAlready && isPlaybillShow"
      >
        <div class="image-save-wrapper" @click="createPlaybill">
          <span>点击保存图片</span>
        </div>
      </div>
    </van-popup>
  </PopInner>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import html2canvas from 'html2canvas'

@Options({
  components: {
    ...componentAnswerCommon,
    VueQrcode,
    html2canvas
  },
  props: {
    modules: Object,
    data: Object,
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      actionTitleActive: `前往抽奖`,
      actionTitleDisable: `重新答题`,
      name: '',
      imgBase64: '',
      imgBgBase64: '',
      imgContentLeftBase64: '',
      imgContentRightBase64: '',
      imgLineBase64: '',
      qrcodeValue: "",
      qrcodeOptions: {
        errorCorrectionLevel: "Q",
      },
      isPlaybillShow: false,
      playbillBase64: '',
      isPlaybillAlready: false,
      isGetPlaybillImageFinished: false,
      loadingToast: {},
    };
  },
  created(){
    this.name = this.$storage.getAccountInfo()[this.$storage.ACCOUNT_NAME]
    this.qrcodeValue = this.$storage.ZS_DOWNLOAD_URL;
    this.getQrcodeValue()
  },
  methods: {
    getQrcodeValue() {
      return this.$http
        .post("/share-img/share", {
          method: 101,
          share_channel: 101,
          share_url: process.env.SHARE_URL
        })
        .then((res) => this.qrcodeValueInit(res));
    },
    qrcodeValueInit(res){
      if(res.code === 200){
        // console.log(res, 'res')
        this.qrcodeValue = res.data.shareRegisterUrl
      }
    },
    goShare() {
      this.$emit("action", {
        type: 1,
        url: `AnswerShare`,
        data: this.data,
      });
    },
    doAction() {
      if (this.data.isCorrect) {
        this.$emit("action", {
          type: 1,
          url: `DrawIndex`,
        });
      } else {
        this.$emit("action", {
          type: 1,
          url: `DailyAnswer`,
        });
      }
    },
    click(k) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    goBack() {
      this.$emit("action", {
        type: 1,
        url: `Index`,
      });
    },
    generatePlaybill(){
      const _this = this
      // 生成头像/图片base64解决跨域问题
      let imgPromise = Promise.resolve('');
      console.log(this.data, 'this.data')
      if(this.data.img){
        const img = this.data.img.indexOf('?')>-1?this.data.img.split('?')[0]:this.data.img
        if(img){
          imgPromise = this.$storage.getUrlBase64(img)
        }
      }
      const imgBgPromise = this.$storage.getUrlBase64(`${_this.rootUrl}/answer/question_share_icon_bg.png`)
      const imgContentLeftPromise = this.$storage.getUrlBase64(`${_this.rootUrl}/answer/question_share_content_left.png`)
      const imgContentRightPromise = this.$storage.getUrlBase64(`${_this.rootUrl}/answer/question_share_content_right.png`)
      const imgLinePromise = this.$storage.getUrlBase64(`${_this.rootUrl}/answer/question_share_content_line.png`)
      Promise.all([imgPromise, imgBgPromise, imgContentLeftPromise, imgContentRightPromise, imgLinePromise]).then((res)=>{
        if(res[0]){
          _this.imgBase64 = res[0]
        }
        if(res[1]){
          _this.imgBgBase64 = res[1];
        }
        if(res[2]){
          _this.imgContentLeftBase64 = res[2];
        }
        if(res[3]){
          _this.imgContentRightBase64 = res[3];
        }
        if(res[4]){
          _this.imgLineBase64 = res[4];
        }
        _this.isPlaybillShow = true;
        _this.$nextTick(()=>{
          html2canvas(_this.$refs.playbill, {
            backgroundColor: null,
            useCORS: true,
            // allowTaint: true,
          }).then((canvas) => {
            let url = canvas.toDataURL('image/png');
            _this.playbillBase64 = url;
            _this.isPlaybillAlready = true;
          })
        })
      })
    },
    showPlaybill(){
      const _this = this
      _this.generatePlaybill()
    },
    closePlaybill(){
      this.isPlaybillShow = false;
      this.isPlaybillAlready = false;
    },
    getUrlBase64(url) {
      return this.$storage.getUrlBase64(url)
    },
    createPlaybill(){
      const _this = this
      const timedate = Date.parse(new Date());
      const fileName = `答题分享-${timedate}`
      const form = new FormData();
      form.append("file", _this.$storage.base64ToFile(_this.playbillBase64, fileName) );
      _this.$http.uploads("/common/upload", form).then((res)=>{
        if(res.data.code === 200 && res.data.data.url){
          _this.$storage.zsImageShare(fileName, _this.playbillBase64, res.data.data.url, _this.qrcodeValue)
        }
      })
    },
  },
})
export default class Hotspot6 extends Vue {}
</script>

<style scoped lang="scss">
.answer-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  padding-bottom: px2rem(32px);
  .cover-wrapper {
    margin: 0 px2rem(46px);
    margin-top: px2rem(61px);
    background: linear-gradient(180deg, #e5f9f3 0%, #ffffff 100%);
    border-radius: px2rem(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: px2rem(79px);
    flex-direction: column;
    box-sizing: border-box;
    padding: px2rem(42px) 0 px2rem(101px) 0;
    position: relative;
    .point-share {
      width: px2rem(142px);
      height: px2rem(48px);
      background: linear-gradient(180deg, #3fc795 0%, #50df7e 100%);
      border-radius: px2rem(44px) 0px 0px px2rem(44px);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: px2rem(49px);
      span {
        font-size: px2rem(24px);
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .point-wrapper {
      width: px2rem(300px);
      height: px2rem(273px);
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
      }
      span {
        position: absolute;
        font-size: px2rem(72px);
        font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
        font-weight: 800;
        color: #ffffff;
        margin-bottom: px2rem(32px);
      }
    }
    .name-wrapper {
      margin-top: px2rem(20px);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: px2rem(24px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
    }
    .line-wrapper {
      margin: 0 auto;
      margin-top: px2rem(44px);
      width: px2rem(520px);
      height: px2rem(2px);
      background: linear-gradient(
        90deg,
        rgba(80, 223, 130, 0) 0%,
        rgba(80, 222, 130, 0.6) 50%,
        rgba(80, 223, 130, 0) 100%
      );
    }
    .answer-info-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      margin-top: px2rem(49px);
      img {
        width: px2rem(75px);
        height: px2rem(25px);
      }
      span {
        font-size: px2rem(24px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin: 0 px2rem(27px);
      }
    }
    .fail-wrapper {
      margin-top: px2rem(54px);
      margin-bottom: px2rem(-15px);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .fail-title {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          font-size: px2rem(28px);
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #212e42;
        }
      }
      img {
        margin-top: px2rem(40px);
        width: px2rem(196px);
        height: px2rem(196px);
      }
    }
  }
  .get-one-award {
    margin-top: px2rem(-39px);
    margin-bottom: px2rem(79px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: px2rem(32px);
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #212e42;
    }
  }
}

.playbill-wrapper {
  width: px2rem(628px);
  box-sizing: border-box;
  background: #ffffff;
  padding: px2rem(50px) px2rem(55px) px2rem(100px) px2rem(55px);
  overflow: hidden;
  border-radius: px2rem(20px);
  .playbill-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    // box-shadow: 0 px2rem(14px) px2rem(20px) px2rem(1px) rgba(149, 225, 175, 0.5);
    .playbill-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: px2rem(40px);
      position: relative;
      span {
        font-size: px2rem(29px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        position: relative;
      }
      span:after {
        background: #96e5b6;
        content: "";
        position: absolute;
        bottom: px2rem(-12px);
        width: 60%;
        height: px2rem(4px);
        left: 20%;
      }
    }
    .playbill-desc {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: px2rem(36px);
      position: relative;
      span {
        font-size: px2rem(26px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #00ce7c;
      }
    }
    .playbill-image {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: px2rem(428px);
      height: px2rem(428px);
      margin-bottom: px2rem(21px);
      position: relative;
      background: url("#{$PublicEnv}/answer/question_share_icon_bg.png");
      background-size: 100% 100%;
      img {
        width: px2rem(324px);
        height: px2rem(259px);
        margin-bottom: px2rem(24px);
      }
      span {
        font-size: px2rem(32px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }
    }
    .playbill-content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: px2rem(60px);
      position: relative;
      .content-left {
        flex-shrink: 0;
        width: px2rem(14px);
        height: px2rem(33px);
        margin-right: px2rem(12px);
        background: url("#{$PublicEnv}/answer/question_share_content_left.png");
        background-size: 100% 100%;
      }
      .content-right {
        flex-shrink: 0;
        width: px2rem(14px);
        height: px2rem(33px);
        margin-left: px2rem(12px);
        background: url("#{$PublicEnv}/answer/question_share_content_right.png");
        background-size: 100% 100%;
      }
      p {
        flex: 1;
        text-align: center;
        font-size: px2rem(34px);
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #00ce7c;
        line-height: px2rem(52px);
      }
    }
    .playbill-line {
      width: px2rem(517px);
      height: px2rem(13px);
      background: url("#{$PublicEnv}/answer/question_share_content_line.png");
      background-size: 100% 100%;
      margin-bottom: px2rem(65px);
    }
    .playbill-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .bottom-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1;
        p {
          font-size: px2rem(24px);
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #00ce7c;
          line-height: px2rem(44px);
        }
      }
      .bottom-right {
        width: px2rem(154px);
        height: px2rem(154px);
        flex-shrink: 0;
        border: px2rem(2px) solid rgba(150, 229, 182, 1);
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.playbill-final {
  width: px2rem(628px);
  box-sizing: border-box;
  img {
    width: 100%;
  }
}
.image-save-wrapper-bg {
  width: 100%;
  height: 100%;
  margin-top: px2rem(150px);
}
.image-save-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: px2rem(606px);
  height: px2rem(88px);
  background: linear-gradient(136deg, #1ee1a4 0%, #58e0c4 100%);
  box-shadow: 0 px2rem(14px) px2rem(20px) px2rem(1px) rgba(149, 225, 175, 0.35);
  border-radius: px2rem(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    font-size: px2rem(32px);
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
