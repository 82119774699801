
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import componentAnswerCommon from "@/assets/js/componentAnswerCommon";

@Options({
  components: {
    ...componentAnswerCommon,
  },
  props: {
    modules: Object,
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      isActionActive: true,
      actionTitleActive: `开始答题`,
      actionTitleDisable: `今日答题次数已用完`,
      grade: "-",
      rank: "-",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    doAnswer() {
      const _this = this;
      if (!_this.isActionActive) {
        return;
      }
      _this.$http.get("/question/start").then((res: any) => {
        if (res.code === 200) {
          _this.$emit("action", {
            type: 1,
            url: `Answer`,
          });
        }
      });
    },
    getData() {
      const _this = this;
      _this.$http.get("/question/before_start").then((res: any) => {
        if (res.code === 200) {
          _this.rank = res.data.top;
          _this.grade = res.data.grade;
          _this.isActionActive = res.data.has_chance;
        } else {
          _this.isActionActive = false;
        }
      });
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
    goBack() {
      this.$emit("action", {
        type: 1,
        url: `Index`,
      });
    },
    goRank() {
      this.$emit("action", {
        type: 1,
        url: `Rank`,
      });
    },
    goRecord() {
      this.$emit("action", {
        type: 1,
        url: `AnswerRecord`,
      });
    },
  },
})
export default class Hotspot6 extends Vue {}
