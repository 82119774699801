
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import videojs from "video.js";
import "videojs-contrib-hls";
import "@videojs/http-streaming";
import "video.js/dist/video-js.css";
import { judgeMobile } from "@/assets/js/storage";

@Options({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    poster: {
      type: String,
      default: "",
    },
    skipShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videoId: `video_${new Date().getTime()}`,
      videoInstance: {},
      actionShow: false,
      clickCount: 5,
      androidPoster:
        judgeMobile().android || judgeMobile().wechat ? this.poster : "",
    };
  },
  watch: {
    show: {
      handler: function (newVal) {
        if (newVal) {
          const _this = this;
          _this.$nextTick(() => {
            if (_this.videoInstance && _this.videoInstance.dispose) {
              _this.videoInstance.dispose();
              _this.videoInstance = null;
            }
            _this.videoInstance = videojs(_this.videoId, {
              controls: false,
              autoplay: judgeMobile().android ? false : true,
              loop: false,
              preload: "auto",
              sources: [
                {
                  type: "application/x-mpegURL",
                  src: this.url, //视频播放地址
                },
              ],
              notSupportedMessage: "此视频暂无法播放，请稍后再试",
              html5: {
                hls: {
                  overrideNative: false,
                },
                nativeVideoTracks: !false,
                nativeAudioTracks: !false,
                nativeTextTracks: !false,
              },
            });
            // console.log(_this.videoInstance, "_this.videoInstance");
            _this.videoInstance.on("ended", _this.end);
            _this.videoInstance.on("loadstart", function () {
              // console.log(res, "开始请求数据");
            });
            if (judgeMobile().mobile) {
              window.addEventListener("touchstart", _this.click, false);
            } else {
              window.addEventListener("click", _this.click, false);
            }
            _this.videoInstance.ready(() => {
              _this.actionShow = true;
              setTimeout(() => {
                _this.videoInstance.volume(0);
                // _this.click();
              }, 800);
            });
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    volumeBegin() {
      const _this = this;
      if (
        _this.videoInstance &&
        _this.videoInstance != null &&
        _this.videoInstance.volume &&
        _this.videoInstance.volume() < 1
      ) {
        _this.videoInstance.volume(_this.videoInstance.volume() + 0.1);
        setTimeout(() => {
          _this.volumeBegin();
        }, 600);
      }
    },
    clickSkip() {
      this.videoInstance.pause();
      this.videoInstance.dispose();
      this.videoInstance = null;
      this.actionShow = false;
      window.removeEventListener("click", this.click);
      window.removeEventListener("touchstart", this.click);
      this.$emit("end");
    },
    end() {
      this.videoInstance.pause();
      this.videoInstance.dispose();
      this.videoInstance = null;
      this.actionShow = false;
      window.removeEventListener("click", this.click);
      window.removeEventListener("touchstart", this.click);
      this.$emit("end");
    },
    click() {
      const _this = this;
      if (_this.clickCount <= 0) {
        return;
      }
      _this.clickCount = _this.clickCount - 1;
      if (_this.videoInstance && _this.videoInstance != null) {
        let promise = _this.videoInstance.play();
        promise
          .then(function () {
            _this.volumeBegin();
            _this.actionShow = false;
          })
          .catch(function (err: any) {
            console.error(_this.clickCount, err, "Autoplay was prevented.");
          });
        _this.$emit("click");
        setTimeout(() => {
          _this.click();
        }, 600);
      }
    },
  },
})
export default class EnterVideo extends Vue {}
