
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isDrawer: {
      type: Boolean,
      default: false,
    },
    isYes: {
      type: Boolean,
      default: false,
    },
    isNo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
})
export default class Back extends Vue {}
