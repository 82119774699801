
import { Options, Vue } from "vue-class-component";
import BackgroundIframe from "@/components/BackgroundIframe.vue";
import OutsideIframe from "@/components/OutsideIframe.vue";
import HotspotPopContainer from "@/components/HotspotPopContainer.vue";
import PrefacePopContainer from "@/components/PrefacePopContainer.vue";
import AnswerPopContainer from "@/components/AnswerPopContainer.vue";
import CartoonFigure from "@/components/CartoonFigure.vue";
import EnterVideo from "@/components/EnterVideo.vue";
import EnterGif from "@/components/EnterGif.vue";
import RightNav from "@/components/RightNav.vue";
import { judgeMobile, getOrient } from "@/assets/js/utils";
import { ShareSheet } from "vant";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
const enum RECEIVE_MESSAGE_EVENTS {
  BEFORE_WALK_TO = "BeforeWalkTo", // 开始走向场景
  AFTER_WALK_TO = "AfterWalkTo", // 点位到达
  VISIT_PANO = "VisitPano", // 场景到达 与AfterWalkTo不同的是，点击场景、场景自动播放可能会经过多个点位与视角旋转，此事件仅在最后的点位且旋转视角完成后触发。
  TO_PANO = "ToPano", // "从上帝视角进入"
  CLICK_TAG = "ClickTag", // 点击置顶热点
  SCENE_READY = "SceneReady", // 场景准备完毕
  CANCEL_LOADING = "CancelLoading", // 场景进入显示页面
  AUTO_PLAY_START = "AutoPlayStart", // 导览开始
  AUTO_PLAY_PAUSE = "AutoPlayPause", // 导览暂停
  GET_MESSAGE_READY = "GetMessageReady", // 准备完毕，可发送事件
}
const enum POST_MESSAGE_EVENTS {
  SET_WELCOME_BTN = "SetWelcomeBtn", // 控制按钮显隐
  SET_SPEED = "SetSpeed", // 控制速度
}
type receiveMessageData = {
  msg: {
    id: number;
    name: string;
    url: string;
  };
  type: string;
};
@Options({
  data() {
    return {
      outsideIframeUrl: "",
      outsideIframeUrlShow: false,
      backgroundIframeUrl:
        "https://cy.idea888.com/admin/play?id=AJilPFsR&cxlweauth=1", // "https://dist.idea888.com/admin/play?id=9taLJabO", //"https://dist.idea888.com/admin/play?id=LdKHiSJ1",
      backgroundIframeIndex: -1,
      backgroundIframeUrlDefault:
        "https://cy.idea888.com/admin/play?id=AJilPFsR&cxlweauth=1&p=100", // "https://dist.idea888.com/admin/play?id=9taLJabO&p=100",
      backgroundIframeUrlSecond:
        "https://dist.idea888.com/admin/play?id=kFrRSXr9&p=101", // "https://dist.idea888.com/admin/play?id=tXBK6VKY&p=101",
      popSpot: "",
      popShow: false,
      transformMaskShow: false,
      popSpotData: {},
      popSpotComponent: "",
      audioBackground: {},
      audioBackgroundIsOver: true,
      audioBackgroundPlaying: false,
      cartoonShow: false,
      cartoonActionIndex: 0,
      rootUrl: process.env.VUE_APP_DEPLOY_URL,
      enterVideoUrl:
        judgeMobile().mobile && getOrient()
          ? `${process.env.VUE_APP_DEPLOY_URL}/video/mobile/mobile.m3u8`
          : `${process.env.VUE_APP_DEPLOY_URL}/video/pc/pc.m3u8`,
      isEnterVideoShow: false,
      enterVideoPoster:
        judgeMobile().mobile && getOrient()
          ? `${process.env.VUE_APP_DEPLOY_URL}/poster/mobile.png`
          : `${process.env.VUE_APP_DEPLOY_URL}/poster/pc.png`,
      isEnterGifShow: false,
      enterGifUrl:
        judgeMobile().mobile && getOrient()
          ? `${process.env.VUE_APP_DEPLOY_URL}/gif/mobile.gif`
          : `${process.env.VUE_APP_DEPLOY_URL}/gif/pc.gif`,
      isRightNavShow: false,
      answerPopShow: false,
      prefacePopShow: false,
      enterVideoSkipShow: false,
      isGuiding: false,
      isInPage: false,
      cartoonTalkShow: false,
      cartoonTalkContent: "",
      currentWalkSpot: 0,
      isCartoonAudioPlaying: false,
      loadingToast: {},
      audioBackgroundMusicPlaying: false,
      bgMusicCustomAction: -1,
      backShow: false,
      lastPageHref: "/",
      isCancelLoading: false,
      isVideoEnd: false,
      isPrefactMusicPlay: false,
      showShare: false,
      shareOptions: [
        { name: "站内", icon: require("../assets/custom-icon-fire.png") },
        { name: "微信", icon: "wechat" },
      ],
    };
  },
  components: {
    BackgroundIframe,
    HotspotPopContainer,
    AnswerPopContainer,
    CartoonFigure,
    EnterVideo,
    EnterGif,
    RightNav,
    PrefacePopContainer,
    OutsideIframe,
    ShareSheet,
  },
  provide() {
    return {
      outsideIframeShow: this.outsideIframeShow,
    };
  },
  mounted() {
    // ..
    const _this = this;
    _this.$toast.clear();
    setTimeout(() => {
      if (!_this.transformMaskShow) {
        _this.isEnterVideoShow = true;
        setTimeout(() => {
          if (_this.isEnterVideoShow) {
            _this.enterVideoSkipShow = true;
          } else {
            _this.isEnterVideoShow = false;
            _this.enterVideoSkipShow = false;
          }
        }, 1500);
      }
    }, 0);
    this.audioBackgroundInit();
  },
  created() {
    const _this = this;
    window.addEventListener("message", this.receiveMessage);
    // 判断APP内
    if (this.$storage.isZsApp()) {
      // 是端内
      console.log("端内");
      this.backShow = true;
    } else {
      // 是端外
      console.log("端外");
      // this.backShow = true;
    }
    // console.log(document.cookie, "cookie");
    // console.log(document.referrer, "document.referrer");
    if (document.referrer) {
      this.lastPageHref = document.referrer;
    }
    // console.log(location.href, "href");
    const key = this.$storage.GetQueryString(
      this.$storage.ZS_AUTHORIZATION_KEY
    );
    console.log(key, this.$storage.ZS_AUTHORIZATION_KEY);
    if (key) {
      this.$http
        .get("/account/token", {
          authorization_key: key,
        })
        .then((res: any) => {
          // console.log(res, "account/token");
          if (res.code === 200) {
            this.$storage.setToken(res.data.zs_token);
            this.$http.post("/account/info").then((res: any) => {
              // console.log(res, "account/info");
              if (res.code === 200) {
                this.$storage.setAccountInfo(res.data);
              }
            });
          }
        });
    }
    const accessToken =
      this.$storage.GetQueryString(this.$storage.ZS_SHARE_ACCESS_TOKEN_KEY) ||
      "";
    localStorage.setItem(this.$storage.ZS_SHARE_ACCESS_TOKEN, accessToken);
    const userId =
      this.$storage.GetQueryString(this.$storage.ZS_SHARE_USER_ID_KEY) || "";
    localStorage.setItem(this.$storage.ZS_SHARE_USER_ID, userId);
    // 统计访客
    this.$http.get("/statistics/record");
    if (this.$storage.judgeMobile().wechat) {
      this.$http
        .get(
          `${process.env.VUE_APP_EXTERNAL_LINK}${process.env.VUE_APP_WECHAT_SHARE_URL}`,
          {
            url: location.href,
            type: "mp",
            debug: false,
            apis: "onMenuShareTimeline,onMenuShareAppMessage",
          }
        )
        .then((res: any) => {
          if (res.code == 200) {
            _this.$storage.setWechatConfig({
              appId: res.data.appId,
              timestamp: res.data.timestamp,
              nonceStr: res.data.nonceStr,
              signature: res.data.signature,
            });
            _this.$storage.setWechatShare({});
          }
        });
    }
  },
  beforeUnmount() {
    window.removeEventListener("message", this.receiveMessage);
  },
  methods: {
    shareSelect(e: any) {
      if (e.name === "微信") {
        this.$storage.zsWxHtmlShare(this); // 一键分享
      } else if (e.name === "站内") {
        this.$storage.zsInnerHtmlShare(this); // 一键分享
      }
      this.showShare = false;
    },
    getSceneAudio(id: number) {
      return this.$http
        .get("/scene/info", {
          id: id,
        })
        .then((res: any) => {
          // console.log(res, "res");
          if (res.code === 200) {
            return {
              url: res.data.audio_url,
              text: res.data.audio_text,
            };
          }
        });
    },
    audioEnd() {
      this.cartoonTalkShow = false;
    },
    prefacePopClose() {
      this.audioClose();
      this.prefacePopShow = false;
      // this.audioBackgroundMusicInit();
    },
    cartoonAudioPlay() {
      const _this = this;
      if (
        !this.popShow &&
        this.isInPage &&
        !this.isEnterVideoShow &&
        !this.prefacePopShow &&
        !this.isGuiding
      ) {
        if (this.isCartoonAudioPlaying) {
          this.audioAction(false);
          this.cartoonTalkShow = false;
          this.isCartoonAudioPlaying = false;
        } else {
          let audioType = -1;
          if (this.currentWalkSpot >= 0 && this.currentWalkSpot <= 36) {
            audioType = 2;
          } else if (
            (this.currentWalkSpot > 36 && this.currentWalkSpot <= 74) ||
            this.currentWalkSpot === 114 ||
            this.currentWalkSpot === 115
          ) {
            audioType = 3;
          } else if (this.currentWalkSpot > 74 && this.currentWalkSpot <= 96) {
            audioType = 4;
          } else if (
            (this.currentWalkSpot >= 97 && this.currentWalkSpot <= 106) ||
            (this.currentWalkSpot >= 114 && this.currentWalkSpot <= 120)
          ) {
            audioType = 5;
          }
          if (audioType > 0) {
            _this
              .getSceneAudio(audioType)
              .then((audioInfo: { url: string; text: string }) => {
                _this.audioPlay(audioInfo.url);
                _this.cartoonTalkContent = audioInfo.text;
                _this.cartoonTalkShow = true;
                _this.isCartoonAudioPlaying = true;
              });
          }
        }
      }
    },
    answerPopClose() {
      this.answerPopShow = false;
      if (this.bgMusicCustomAction < 2) {
        this.audioBackgroundMusicPlayOrStop(1);
      }
      if (this.$storage.isZsApp()) {
        this.backShow = true;
      }
    },
    navClick(item: any) {
      const _this = this;
      if (item) {
        if (item.type == -1) {
          this.audioBackgroundMusicPlayOrStop();
          if (this.audioBackgroundMusicPlaying) {
            this.bgMusicCustomAction = 1;
          } else {
            this.bgMusicCustomAction = 2;
          }
        } else if (item.type == 2) {
          _this.$storage.isWxMini(this, () => {
            _this.$storage.goZsAppPage(this, 1);
          });
        } else if (item.type == 4) {
          _this.$storage.isWxMini(this, () => {
            _this.$storage.checkAccountInfo(_this).then((info: any) => {
              if (info) {
                // _this.$storage.goZsAppPage(_this, 7); // 我的体脂师
                _this.$storage.goZsAppPage(_this, 13);
              }
            });
          });
        } else if (item.type == 5) {
          _this.$storage.isWxMini(this, () => {
            _this.$storage.checkAccountInfo(_this).then((info: any) => {
              if (info) {
                _this.$storage.goZsAppPage(_this, 5);
              }
            });
          });
        } else if (item.type == 6) {
          _this.$storage.isWxMini(this, () => {
            _this.outsideIframeShow(
              "https://www.zhi20.com/home/about?cat_id=40"
            );
          });
        } else if (item.type == 3) {
          _this.$storage.isWxMini(this, () => {
            this.$storage.checkAccountInfo(this).then((info: any) => {
              if (info) {
                // this.$toast({
                //   message: "近日即将上线",
                // });
                // _this.answerPopShow = true;
                // _this.backShow = false;
                _this.$storage.goZsAppPage(_this, 14);
              }
            });
          });
        } else if (item.type == 0) {
          this.prefacePopShow = true;
          _this.audioBackgroundMusicPlayOrStop(2);
          _this
            .getSceneAudio(1)
            .then((audioInfo: { url: string; text: string }) => {
              _this.audioPlay(audioInfo.url);
            });
        } else if (item.type == 7) {
          if (this.$storage.isZsApp()) {
            _this.$storage.zsWxHtmlShare(this); // 一键分享
          } else {
            _this.$storage.isWxMini(this, () => {
              _this.$storage.goRegister();
            });
          }
        } else {
          this.$toast({
            message: "即将上线...",
          });
        }
      }
    },
    videoClick() {
      if (!this.audioBackground) {
        this.audioBackgroundInit();
      }
      this.audioBackground.src = "";
      this.audioBackground.load();
    },
    videoEnd() {
      const _this = this;
      _this.isEnterVideoShow = false;
      _this.enterVideoSkipShow = false;
      _this.isVideoEnd = true;
      _this.prefaceMusicPlay(_this.isCancelLoading, _this.isVideoEnd);
    },
    prefaceMusicPlay(isCancelLoading: boolean, isVideoEnd: boolean) {
      const _this = this;
      if (isCancelLoading && isVideoEnd && !_this.isPrefactMusicPlay) {
        _this.isPrefactMusicPlay = true;
        _this
          .getSceneAudio(1)
          .then((audioInfo: { url: string; text: string }) => {
            _this.audioPlay(audioInfo.url);
          });
      }
    },
    doMemberAction(type: number) {
      this.$dialog
        .confirm({
          message: type === 1 ? "是否前往黄金会员区？" : "是否返回普通会员区？",
          teleport: "#app",
        })
        .then(() => {
          this.transformMaskAction();
          // this.backgroundIframeUrl = "";
          setTimeout(() => {
            // this.backgroundIframeUrl =
            //   type === 1
            //     ? this.backgroundIframeUrlSecond
            //     : this.backgroundIframeUrlDefault;
            if (type === 1) {
              this.backgroundIframeIndex = 2;
            } else {
              this.backgroundIframeIndex = 1;
            }
          }, 1000);
        });
    },
    transformMaskAction() {
      this.transformMaskShow = true;
      this.loadingToast = this.$toast.loading({
        message: "场景切换中...",
        duration: 0,
        // forbidClick: true,
        overlay: false,
        loadingType: "spinner",
        className: "transform-mask-loader",
        teleport: "#app",
      });
    },
    transformMaskClose() {
      this.transformMaskShow = false;
      this.loadingToast.clear && this.loadingToast.clear();
    },
    audioAction(e: boolean) {
      if (this.audioBackgroundIsOver) {
        // 背景音乐已结束
        if (e) {
          this.audioBackgroundPlaying = true;
          this.audioBackgroundIsOver = false;
          this.audioBackground.src = "";
          this.audioBackground.src = `${this.rootUrl}/hotspot/final/${this.popSpot}/${this.popSpot}_audio.mp3`;
          // console.log("reload");
        }
      } else {
        // 背景音乐未结束
        if (!e) {
          this.audioBackground.pause();
          this.audioBackgroundPlaying = false;
        } else {
          this.audioBackground.play();
          this.audioBackgroundPlaying = true;
        }
      }
    },
    audioClose() {
      this.audioBackground.src = "";
      this.audioBackgroundIsOver = true;
      this.audioBackgroundPlaying = false;
    },
    audioPlay(src: string) {
      if (!this.audioBackground) {
        this.audioBackgroundInit();
      }
      if (!this.audioBackgroundIsOver) {
        this.audioBackground.pause();
        this.audioBackgroundPlaying = false;
        this.audioBackgroundIsOver = false;
      }
      this.audioBackground.src = "";
      this.audioBackground.src = src;
      this.audioBackground.play();
      this.audioBackgroundPlaying = true;
      this.audioBackgroundIsOver = false;
    },
    audioBackgroundMusicInit(type: number) {
      const _this = this;
      if (
        this.$refs &&
        this.$refs["audioBackgroundMusic"] &&
        this.$refs["audioBackgroundMusic"].src &&
        this.$refs["audioBackgroundMusic"].src.indexOf(".mp3") > -1
      ) {
        return this.audioBackgroundMusicPlayOrStop(type);
      }
      _this
        .getSceneAudio(7)
        .then((audioInfo: { url: string; text: string }) => {
          _this.$refs["audioBackgroundMusic"].src = audioInfo.url;
          _this.audioBackgroundMusicPlayOrStop(type);
        });
    },
    audioBackgroundMusicPlayOrStop(type: number) {
      if (
        !(
          this.$refs &&
          this.$refs["audioBackgroundMusic"] &&
          this.$refs["audioBackgroundMusic"].src &&
          this.$refs["audioBackgroundMusic"].src.indexOf(".mp3") > -1
        )
      ) {
        return this.audioBackgroundMusicInit(type);
      }
      if (type === 1) {
        this.audioBackgroundMusicPlaying = false;
      } else if (type === 2) {
        this.audioBackgroundMusicPlaying = true;
      }
      if (this.audioBackgroundMusicPlaying) {
        this.$refs["audioBackgroundMusic"].autoplay = false;
        this.$refs["audioBackgroundMusic"].pause();
        this.audioBackgroundMusicPlaying = false;
      } else {
        this.$refs["audioBackgroundMusic"].autoplay = true;
        this.$refs["audioBackgroundMusic"].play();
        this.audioBackgroundMusicPlaying = true;
      }
    },
    audioBackgroundInit() {
      const that = this;
      if (this.$refs && this.$refs["audioBackground"]) {
        this.audioBackground = this.$refs["audioBackground"];
        this.audioBackground.autoplay = true;
        this.audioBackground.addEventListener(
          "ended",
          function () {
            that.audioClose();
          },
          false
        );
      }
    },
    popClose() {
      this.popShow = false;
      this.popSpot = "";
      this.popSpotData = {};
      this.popSpotComponent = "";
      this.popUrl = "";
      this.audioClose();
      if (this.bgMusicCustomAction < 2) {
        this.audioBackgroundMusicPlayOrStop(1);
      }
      let random = Math.random() * 10;
      this.cartoonActionIndex = random >= 5 ? 2 : 0;
      if (this.$storage.isZsApp()) {
        this.backShow = true;
      }
    },
    receiveMessage(e: any): void {
      const data: receiveMessageData = e.data;
      const _this = this;
      switch (e.data.type) {
        case RECEIVE_MESSAGE_EVENTS.BEFORE_WALK_TO: {
          // console.log("开始走向:", data.msg.id);
          break;
        }
        case RECEIVE_MESSAGE_EVENTS.AFTER_WALK_TO: {
          console.log("点位到达:", data.msg.id);
          _this.currentWalkSpot = data.msg.id;
          if (_this.isGuiding) {
            if (data.msg.id == 56) {
              // console.log("场景到达:减脂舱");
              _this
                .getSceneAudio(3)
                .then((audioInfo: { url: string; text: string }) => {
                  _this.audioPlay(audioInfo.url);
                  _this.cartoonTalkContent = audioInfo.text;
                  _this.cartoonTalkShow = true;
                });
            } else if (data.msg.id == 85) {
              // console.log("场景到达:活动舱");
              _this
                .getSceneAudio(4)
                .then((audioInfo: { url: string; text: string }) => {
                  _this.audioPlay(audioInfo.url);
                  _this.cartoonTalkContent = audioInfo.text;
                  _this.cartoonTalkShow = true;
                });
            } else if (data.msg.id == 99) {
              // console.log("场景到达:会员舱");
              _this
                .getSceneAudio(5)
                .then((audioInfo: { url: string; text: string }) => {
                  _this.audioPlay(audioInfo.url);
                  _this.cartoonTalkContent = audioInfo.text;
                  _this.cartoonTalkShow = true;
                });
            } else if (data.msg.id == 112) {
              _this
                .getSceneAudio(6)
                .then((audioInfo: { url: string; text: string }) => {
                  _this.audioPlay(audioInfo.url);
                  _this.cartoonTalkContent = audioInfo.text;
                  _this.cartoonTalkShow = true;
                });
            }
          } else {
            if (data.msg.id == 112 || data.msg.id == 108) {
              _this
                .getSceneAudio(6)
                .then((audioInfo: { url: string; text: string }) => {
                  _this.audioPlay(audioInfo.url);
                  _this.cartoonTalkContent = audioInfo.text;
                  _this.cartoonTalkShow = true;
                });
            }
          }
          break;
        }
        case RECEIVE_MESSAGE_EVENTS.AUTO_PLAY_START: {
          // console.log("AUTO_PLAY_START");
          _this.isGuiding = true;
          break;
        }
        case RECEIVE_MESSAGE_EVENTS.AUTO_PLAY_PAUSE: {
          // console.log("AUTO_PLAY_PAUSE");
          _this.isGuiding = false;
          break;
        }
        case RECEIVE_MESSAGE_EVENTS.VISIT_PANO: {
          console.log("场景到达:", data.msg.id);
          if (_this.isGuiding) {
            if (data.msg.id == 0) {
              // console.log("场景到达:入口");
              _this
                .getSceneAudio(2)
                .then((audioInfo: { url: string; text: string }) => {
                  _this.audioPlay(audioInfo.url);
                  _this.cartoonTalkContent = audioInfo.text;
                  _this.cartoonTalkShow = true;
                });
            }
          }
          break;
        }
        case RECEIVE_MESSAGE_EVENTS.TO_PANO: {
          // console.log("从上帝视角进入:", data.msg.id);
          break;
        }
        case RECEIVE_MESSAGE_EVENTS.SCENE_READY: {
          // console.log("SCENE_READY");
          // 加载视频
          break;
        }
        case RECEIVE_MESSAGE_EVENTS.GET_MESSAGE_READY: {
          console.log("iframe 准备完毕，可以发送事件");
          try {
            const contentWindow = (document.getElementById(
              "backgroundIframe1"
            ) as HTMLIFrameElement)?.contentWindow;
            contentWindow?.postMessage(
              {
                type: POST_MESSAGE_EVENTS.SET_WELCOME_BTN,
                msg: { isShow: false },
              },
              "*"
            );
            contentWindow?.postMessage(
              { type: POST_MESSAGE_EVENTS.SET_SPEED, msg: { speed: "0.5" } },
              "*"
            );
          } catch (e) {
            console.log("iframe post message error:", e);
          }
          break;
        }
        case RECEIVE_MESSAGE_EVENTS.CANCEL_LOADING: {
          console.log("CANCEL_LOADING");
          _this.cartoonShow = true;
          _this.isRightNavShow = true;
          if (!_this.transformMaskShow) {
            _this.prefacePopShow = true;
          }
          _this.isCancelLoading = true;
          _this.prefaceMusicPlay(_this.isCancelLoading, _this.isVideoEnd);
          // _this.audioBackgroundMusicInit();
          this.isInPage = true;
          _this.transformMaskClose();
          break;
        }
        case RECEIVE_MESSAGE_EVENTS.CLICK_TAG: {
          console.log(`点击热点：${data.msg.url}`);
          _this.popSpot = data.msg.url;
          if (data.msg.url == "101") {
            // 黄金会员专区
            // this.doMemberAction(1);
            _this.$storage.checkAccountInfo(_this).then((info: any) => {
              if (info) {
                this.doMemberAction(1);
              }
            });
          } else if (data.msg.url == "100") {
            // 普通会员专区
            this.doMemberAction(2);
          } else if (data.msg.url == "18") {
            _this.outsideIframeShow("https://activeqd.shouba.cn/kanban");
            // window.open("https://activeqd.shouba.cn/kanban");
          } else {
            if (data.msg.url == "32") {
              _this.$storage.checkAccountInfo(_this).then((info: any) => {
                if (info) {
                  _this.showHotspot();
                }
              });
            } else {
              _this.showHotspot();
            }
            // _this.popSpot = "15";
          }
          // 浏览纪录
          // this.$http.get("/statistics/record");
          break;
        }
      }
    },
    showHotspot() {
      const _this = this;
      // _this.popSpot = "10";
      const loadingToast = _this.$toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true,
        overlay: false,
        loadingType: "spinner",
        className: "transform-mask-loader",
        teleport: "#app",
      });
      _this.$http
        .get(
          "/hot-spot/info",
          { id_num: _this.popSpot, json_to_object: 1 },
          false
        )
        .then((res: any) => {
          _this.popSpotData = res.data.content; // JSON.parse(res.data.content);
          _this.popSpotComponent = `Hotspot${_this.popSpot}`;
          loadingToast.clear();
          _this.popShow = true;
          _this.backShow = false;
          _this.audioBackgroundMusicPlayOrStop(2);
          if (res.data.audio_url) {
            _this.audioPlay(res.data.audio_url);
            _this.cartoonActionIndex = 1;
          }
        });
    },
    hotSpotAudioSinglePlay(url: string) {
      this.audioBackgroundMusicPlayOrStop(2);
      this.audioPlay(url);
    },
    outsideIframeShow(url: string) {
      if (this.$storage.isZsApp()) {
        this.outsideIframeUrl = url;
        this.outsideIframeUrlShow = true;
        this.backShow = true;
      } else {
        this.outsideIframeUrl = url;
        this.outsideIframeUrlShow = true;
        this.backShow = true;
        // window.open(url);
      }
    },
    goBack() {
      // console.log(window.history, "window.history");
      if (this.outsideIframeUrlShow) {
        this.outsideIframeUrlShow = false;
        if (this.$storage.isZsApp()) {
          this.backShow = true;
        } else {
          this.backShow = false;
        }
      } else if (this.answerPopShow) {
        this.answerPopClose();
      } else {
        // window.history.go(-(window.history.length - 1));
        // window.location.replace(this.lastPageHref);
        this.$storage.zsAppPageBack();
      }
    },
  },
})
export default class Index extends Vue {}
