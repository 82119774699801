
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";
import HotspotContentList from "@/components/Common/HotspotContentList.vue";

@Options({
  components: {
    HotspotContentList,
  },
  props: {
    modules: Object,
  },
  data() {
    return {};
  },
  inject: ["outsideIframeShow"],
  methods: {
    buy() {
      const _this = this;
      _this.$storage.checkAccountInfo(_this).then((info: any) => {
        if (info) {
          _this.$storage.goZsAppPage(_this, 11);
        }
      });
    },
    audiobgstop() {
      this.$emit("audiobgstop");
    },
    click(k: number) {
      this.currentIndex = k;
      this.showDetail = true;
    },
  },
})
export default class Hotspot10 extends Vue {}
